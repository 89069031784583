import { useEffect, useState } from 'react';
import feather from 'feather-icons';
import * as Tabs from '@radix-ui/react-tabs';
import ReactQuill from 'react-quill'
import Accordion from 'react-bootstrap/Accordion';
import { toolbarOptions } from '../../constants/config'
// import { Tab, Tabs } from 'react-bootstrap'
import Select from 'react-select'
import MediaUploader from '../../components/media-uploader/MediaUploader';
import { AiOutlineClose } from 'react-icons/ai';
import StatusCard from '../../components/StatusCard';
import History from '../../components/History';
import Seo from '../../components/Seo';

export default function AddPackages() {

    const [logoPreviews, setLogoPreviews] = useState([]);
    const [selectedOption, setSelectedOption] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [accordions, setAccordions] = useState([
        { name: 'Highlight One' }
    ]);


    useEffect(() => {

        feather.replace();
    }, []);

    const handleImageChange = (e) => {
        const files = Array.from(e.target.files);
        const newPreviews = files.map(file => ({
            file,
            preview: URL.createObjectURL(file),
        }));
        setImagePreviews([...imagePreviews, ...newPreviews]);
    };

    const handleRemoveImage = (index) => {
        const newPreviews = imagePreviews.filter((_, i) => i !== index);
        setImagePreviews(newPreviews);
    };

    const handleLogoUpload = (e) => {
        const files = Array.from(e.target.files);
        const newLogoPreviews = files.map(file => ({
            file,
            preview: URL.createObjectURL(file),
        }));
        setLogoPreviews([...logoPreviews, ...newLogoPreviews]);
    };
    const handleRemoveLogo = (index) => {
        const newLogoPreviews = logoPreviews.filter((_, i) => i !== index);
        setLogoPreviews(newLogoPreviews);
    };

    const handleChange = (selected) => {
        setSelectedOption(selected || []);
    };

    const handleDelete = (optionToDelete) => {
        setSelectedOption(prev => prev.filter(option => option.value !== optionToDelete.value));
    };
    const activities = [
        { value: 'activity1', label: 'Activities' },
        { value: 'actractions', label: 'Actractions' },
    ]

    const options = [
        { value: 'Jan', label: 'Jan' },
        { value: 'Feb', label: 'Feb' },
        { value: 'Mar', label: 'Mar' },
        { value: 'Apr', label: 'Apr' },
        { value: 'May', label: 'May' },
        { value: 'Jun', label: 'Jun' },
    ];

    const optionsOne = [
        { value: 'option1', label: 'Option 1' },
        { value: 'option2', label: 'Option 2' }
    ];

    const addAccordion = () => {
        const newAccordionIndex = accordions.length + 1;
        setAccordions([...accordions, { name: `Highlight ${newAccordionIndex}` }]);
    };


    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3">Add Package </h5>
                        <div className="row">
                            <Tabs.Root className="TabsRoot" defaultValue="tab1">
                                <Tabs.List className="TabsList" aria-label="Manage your account">
                                    <Tabs.Trigger className="TabsTrigger" value="tab1">
                                        Package Details
                                    </Tabs.Trigger>
                                    <Tabs.Trigger className="TabsTrigger" value="tab2">
                                        Itinerary
                                    </Tabs.Trigger>
                                    <Tabs.Trigger className="TabsTrigger" value="tab3">
                                        Highlights
                                    </Tabs.Trigger>
                                    <Tabs.Trigger className="TabsTrigger" value="tab4">
                                        Costing
                                    </Tabs.Trigger>
                                    <Tabs.Trigger className="TabsTrigger" value="tab5">
                                        Gallary
                                    </Tabs.Trigger>
                                    <Tabs.Trigger className="TabsTrigger" value="tab6">
                                        SEO
                                    </Tabs.Trigger>
                                </Tabs.List>
                                <Tabs.Content className="TabsContent" value="tab1">
                                    <div>
                                        <h5 className="card-title border-style mb-3">Package Info</h5>
                                        <div className="row mb-4">
                                            <div className="col-12">
                                                <div className="row">

                                                    <div className="col-12 ">
                                                        <div className="mb-3">
                                                            <label className="form-label">Package Name</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Type here"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-12 col-md-6">
                                                    <div className="mb-3">
                                                        <label className="form-label"> Thumbnail <span>(Recommended 1250*650)</span></label>
                                                        <input type="file" className="form-control" placeholder="Type here" />

                                                    </div>
                                                </div>



                                                <div className="col-12">
                                                    <div className="row">
                                                        {logoPreviews.map((media, index) => (
                                                            <div key={index} className="col-3 preview-imagemargin">
                                                                <MediaUploader
                                                                    media={media}
                                                                    onRemove={() => handleRemoveLogo(index)}
                                                                />
                                                            </div>
                                                        ))}

                                                        <div className="col-3">
                                                            <div className="mb-3">
                                                                <label className="form-label"> Add Banner <span>(Recommended 1250*650)</span> </label>
                                                                <MediaUploader
                                                                    onUpload={handleLogoUpload}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                            </div>



                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 ">
                                                        <div className="mb-3">
                                                            <label className="form-label">Overview</label>
                                                            <ReactQuill theme="snow" modules={{
                                                                toolbar: toolbarOptions
                                                            }}
                                                                placeholder='Type here'
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label">Season</label>
                                                            <select className="form-select">
                                                                <option value="">Select Season</option>

                                                                <option value="Summer">
                                                                    Summer
                                                                </option>
                                                                <option value="Winter">
                                                                    Winter
                                                                </option>
                                                                <option value="Spring">
                                                                    Spring
                                                                </option>

                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label">Duration</label>
                                                            <input
                                                                type="Number"
                                                                className="form-control"
                                                                placeholder="Type here"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-md-4">
                                                        <div className="mb-3">
                                                            <label className="form-label">Anticipated Travel KLM</label>

                                                            <input
                                                                type="Number"
                                                                className="form-control"
                                                                placeholder="Type here"
                                                            />
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div>
                                        <h5 className="card-title border-style mb-3">Inclusion</h5>
                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Choose Inclusion</label>
                                                            <Select
                                                                isMulti
                                                                value={selectedOption}
                                                                onChange={handleChange}
                                                                options={options}
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select Inclusion"


                                                            />
                                                        </div>
                                                    </div>

                                                    {selectedOption.map((option) => (
                                                        <div key={option.value} className="col-12 mb-3">
                                                            <div className="d-inline-block mb-1">
                                                                <button
                                                                    className="btn btn-danger "
                                                                    type="button"
                                                                >
                                                                    {option.label}
                                                                    <span
                                                                        className="close_button"
                                                                        onClick={() => handleDelete(option)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        &times;
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <h5 className="card-title border-style mb-3">Exclusion</h5>
                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Choose Exclusion</label>
                                                            <Select
                                                                isMulti
                                                                value={selectedOption}
                                                                onChange={handleChange}
                                                                options={options}
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select Inclusion"


                                                            />
                                                        </div>
                                                    </div>

                                                    {selectedOption.map((option) => (
                                                        <div key={option.value} className="col-12 mb-3">
                                                            <div className="d-inline-block mb-1">
                                                                <button
                                                                    className="btn btn-danger "
                                                                    type="button"
                                                                >
                                                                    {option.label}
                                                                    <span
                                                                        className="close_button"
                                                                        onClick={() => handleDelete(option)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        &times;
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                    <div>
                                        <h5 className="card-title border-style mb-3">Associated Blogs</h5>
                                        <div className="row ">
                                            <div className="col-12">
                                                <div className="row">
                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Choose Associated  Blogs</label>

                                                            <Select
                                                                isMulti
                                                                value={selectedOption}
                                                                onChange={handleChange}
                                                                options={options}
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select Inclusion"


                                                            />
                                                        </div>
                                                    </div>


                                                    {selectedOption.map((option) => (
                                                        <div key={option.value} className="col-12 mb-3">
                                                            <div className="d-inline-block mb-1">
                                                                <button
                                                                    className="btn btn-danger "
                                                                    type="button"
                                                                >
                                                                    {option.label}
                                                                    <span
                                                                        className="close_button"
                                                                        onClick={() => handleDelete(option)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        &times;
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <div className="col-12 mt-3">
                                        <button type="submit" className="btn btn-primary me-3">Next</button>

                                    </div>
                                </Tabs.Content>
                                <Tabs.Content className="TabsContent" value="tab2">
                                    <div className="row mb-4">
                                        <div className="col-12 ">
                                            <div className="col-6 mb-5">
                                                <div className="mb-3">
                                                    <label className="form-label">Main Map </label>
                                                    <input
                                                        type="file"
                                                        className="form-control"
                                                        placeholder="Type here"
                                                    />
                                                </div>

                                            </div>

                                            <h5 className="card-title mb-4 border-style">Add Itinerary</h5>

                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item eventKey="0">
                                                    <Accordion.Header>Day One</Accordion.Header>
                                                    <Accordion.Body>

                                                        <div className="row mb-5">
                                                            <div className="col-12 ">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Day Title</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Type here"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Day Description</label>
                                                                    <ReactQuill
                                                                        theme="snow"
                                                                        modules={{ toolbar: toolbarOptions }}
                                                                        placeholder="Type here"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Activities</label>
                                                                    <Select
                                                                        isMulti
                                                                        value={selectedOption}
                                                                        onChange={handleChange}
                                                                        options={options}
                                                                        closeMenuOnSelect={false}
                                                                        placeholder="Select Inclusion"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mb-3">
                                                                {selectedOption.map((option) => (
                                                                    <div key={option.value} className="d-inline-block mb-1">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                        >
                                                                            {option.label}
                                                                            <span
                                                                                className="close_button"
                                                                                onClick={() => handleDelete(option)}
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                &times;
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <div className="col-12 col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Attractions</label>
                                                                    <Select
                                                                        isMulti
                                                                        value={selectedOption}
                                                                        onChange={handleChange}
                                                                        options={options}
                                                                        closeMenuOnSelect={false}
                                                                        placeholder="Select Inclusion"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mb-3">
                                                                {selectedOption.map((option) => (
                                                                    <div key={option.value} className="d-inline-block mb-1">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                        >
                                                                            {option.label}
                                                                            <span
                                                                                className="close_button"
                                                                                onClick={() => handleDelete(option)}
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                &times;
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Map Image</label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            placeholder="Type here"
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <div className="col-6">
                                                                    <div className="mb-3">
                                                                        <label className="form-label">Mobile App image</label>
                                                                        <input
                                                                            type="file"
                                                                            className="form-control"
                                                                            placeholder="Type here"
                                                                        />
                                                                    </div>

                                                                </div>

                                                            </div>

                                                            <div className="col-12">
                                                                <button type="submit" className="btn btn-primary me-3">Save</button>
                                                                <button type="submit" className="btn btn-danger ">Cancel</button>

                                                            </div>
                                                        </div>

                                                    </Accordion.Body>
                                                </Accordion.Item>


                                                <Accordion.Item eventKey="1">
                                                    <Accordion.Header>Day Two</Accordion.Header>
                                                    <Accordion.Body>

                                                        <div className="row mb-5">

                                                            <div className="col-12 ">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Day Title</label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder="Type here"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="col-12">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Description</label>
                                                                    <ReactQuill
                                                                        theme="snow"
                                                                        modules={{ toolbar: toolbarOptions }}
                                                                        placeholder="Type here"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 col-md-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Activities</label>
                                                                    <Select
                                                                        isMulti
                                                                        value={selectedOption}
                                                                        onChange={handleChange}
                                                                        options={options}
                                                                        closeMenuOnSelect={false}
                                                                        placeholder="Select Inclusion"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12 mb-3">
                                                                {selectedOption.map((option) => (
                                                                    <div key={option.value} className="d-inline-block mb-1">
                                                                        <button
                                                                            className="btn btn-danger"
                                                                            type="button"
                                                                        >
                                                                            {option.label}
                                                                            <span
                                                                                className="close_button"
                                                                                onClick={() => handleDelete(option)}
                                                                                style={{ cursor: 'pointer' }}
                                                                            >
                                                                                &times;
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                ))}
                                                            </div>

                                                            <div className="col-6">
                                                                <div className="mb-3">
                                                                    <label className="form-label">Map Image</label>
                                                                    <input
                                                                        type="file"
                                                                        className="form-control"
                                                                        placeholder="Type here"
                                                                    />
                                                                </div>
                                                            </div>

                                                            <div className="col-12">
                                                                <button type="submit" className="btn btn-primary me-3">Submit</button>
                                                                <button type="submit" className="btn btn-danger">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>

                                            <div className="col-12 mt-5">
                                                <button type="submit" className="btn btn-primary me-3">Back</button>
                                                <button type="submit" className="btn btn-primary">Next</button>
                                            </div>
                                        </div>
                                    </div>
                                </Tabs.Content>

                                <Tabs.Content className="TabsContent" value="tab3">

                                    <div className='mt-5'>
                                        <Accordion defaultActiveKey="0">
                                            {accordions.map((accordion, index) => (
                                                <Accordion.Item eventKey={index.toString()} key={index}>
                                                    <Accordion.Header>{accordion.name}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row mb-4">
                                                            <div className="col-12">
                                                                <div className="row mb-5">
                                                                    <div className="col-12 col-md-4">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Highlight Name</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Type here"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-md-4">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Link Type</label>
                                                                            <Select
                                                                                options={activities}
                                                                                placeholder="Select an Option"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12 col-md-4">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Linked Item</label>
                                                                            <Select
                                                                                options={optionsOne}
                                                                                placeholder="Select an Option"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12">
                                                                        <div className="mb-3">
                                                                            <label className="form-label">Highlight Description</label>
                                                                            <input
                                                                                type="text"
                                                                                className="form-control"
                                                                                placeholder="Type here"
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-12">
                                                                        <button type="submit" className="btn btn-primary me-3">Save</button>
                                                                        <button type="button" className="btn btn-danger">Cancel</button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            ))}
                                        </Accordion>


                                        <div className="text-end mt-4">
                                            <button className="btn btn-success" onClick={addAccordion}>
                                                Add Highlight
                                            </button>
                                        </div>
                                    </div>

                                    <div className="col-12 ">
                                        <button type="submit" className="btn btn-primary me-3">Back</button>
                                        <button type="submit" className="btn btn-primary">Next</button>
                                    </div>
                                </Tabs.Content>
                                <Tabs.Content className="TabsContent" value="tab4">
                                    <div>
                                        <h5 className="card-title mb-3 border-style">Costing</h5>

                                        <div className="row mb-4">

                                            <div className="row">
                                                <div className="col-6">

                                                    <label className="form-label">Fixed Package Cost <span>(Per Adult)</span></label>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Type here"
                                                    />
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary me-3">Back</button>
                                        <button type="submit" className="btn btn-primary">Next</button>
                                    </div>

                                </Tabs.Content>


                                <Tabs.Content className="TabsContent" value="tab5">
                                    <h5 className="card-title mb-3 border-style">Gallary</h5>
                                    <div className="row">
                                        <div className="col-12 col-md-6 mb-3">
                                            <label className="form-label">Gallery Images</label>
                                            <input
                                                type="file"
                                                className="form-control"
                                                multiple
                                                accept="image/*"
                                                onChange={handleImageChange}
                                            />
                                        </div>
                                        <div className="col-12 col-md-4 mb-3">
                                            <label className="form-label">Upload Videos</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder='Video url'


                                            />

                                        </div>
                                        <div className="col-12 col-md-2 mb-3 margin-container">
                                            <button type="submit" className="btn btn-primary m-2">Add</button>
                                        </div>
                                    </div>

                                    <div className="container">
                                        <div className="row">
                                            {imagePreviews.length > 0 ? (
                                                imagePreviews.map((item, index) => (
                                                    <div className="col-12 col-md-4 col-lg-3 mb-3" key={index}>
                                                        <div className="uploader-preview">
                                                            <div className="image-showcontainer">

                                                                <img src={item.preview} alt="Uploaded Preview" className="img-fluid" />

                                                                <AiOutlineClose
                                                                    size={30}
                                                                    className="remove-icon"
                                                                    onClick={() => handleRemoveImage(index)}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))
                                            ) : (
                                                <div className="col-12">
                                                    <div className="empty-placeholder">To previews available</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button type="submit" className="btn btn-primary me-3">Back</button>
                                        <button type="submit" className="btn btn-primary">Next</button>
                                    </div>
                                </Tabs.Content>

                                <Tabs.Content className="TabsContent" value="tab6">
                                
                                    <Seo/>

                                            <div className="col-12 ">
                                                <button type="submit" className="btn btn-primary me-3">Back</button>
                                                <button type="submit" className="btn btn-primary">Publish</button>
                                            </div>
                                    

                                </Tabs.Content>

                            </Tabs.Root>


                        </div>

                    </div>
                </div>


            <StatusCard/>

              <History/>

            </div>
        </div>
    );
}
