import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { toolbarOptions } from "../../constants/config";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  detail_faq_loading,
  faq_details,
  update_faq_loading,
} from "../../redux/commonReducer";
import {
  getEssentialInfoDetail,
  insertEssentialInfo,
  updateEssentialInfo,
} from "../../redux/actionCreator";

export default function AddEssential() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector(detail_faq_loading);
  const submitLoading = useSelector(update_faq_loading);
  const details = useSelector(faq_details);

  const [data, setData] = useState({
    question: "",
    description: "",
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getEssentialInfoDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        question: details?.question,
        description: details?.description,
      };
      setData({ ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!data?.question?.trim()?.length) {
      temp = {
        ...temp,
        question: "Please enter question",
      };
    }
    if (!data?.description?.trim()?.length) {
      temp = {
        ...temp,
        description: "Please enter answer",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        question: data?.question?.trim(),
        description: data?.description,
      };
      if (id) {
        raw = {
          ...raw,
          id: id,
        };
        dispatch(
          updateEssentialInfo(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertEssentialInfo(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      question: "",
      description: "",
    });
    navigate("/manage-essential");
  };
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Add Essential Information </h5>
            <div className="row mt-3">
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Question</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("question", e?.target?.value)
                    }
                    value={data?.question}
                  />
                  <small id="error" className="error">
                    {err?.question}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Answer</label>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    placeholder="Type here"
                    onChange={(e) => onChangeHandler("description", e)}
                    value={data?.description}
                  />
                  <small id="error" className="error">
                    {err?.description}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary m-2"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
