import React from 'react'
import MediaUploader from './media-uploader/MediaUploader'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../constants/config'

const PolicyContent = ({title}) => {
  return (
    <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3 ">{title}</h5>
                        <div className="row">
                            <div className="col-12 ">
                                <div className="mb-3">
                                    <label className="form-label"> Background Image</label>
                                    <MediaUploader />
                                </div>
                            </div>
                            <div className="col-12 ">
                                <div className="mb-3">
                                    <label className="form-label"> Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>




                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Description</label>
                                    <ReactQuill theme="snow" modules={{
                                        toolbar: toolbarOptions
                                    }}
                                        placeholder='Type here'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default PolicyContent