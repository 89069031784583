

import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { FaSlidersH } from "react-icons/fa";

import { useTable, useSortBy } from 'react-table';
import ReactPaginate from 'react-paginate';

const data = [
    { slno: '1', code: "1234567dfgbh", title: "Winter Free", limit: '3', type: "20%",date:"12 oct 2024"  },


];

const columns = [
    { Header: 'Sl no', accessor: 'slno' },
    { Header: 'Created Date', accessor: 'date' },
    { Header: 'Discount Code ', accessor: 'code' },
    { Header: 'Title ', accessor: 'title' },
    { Header: 'Discount Type ', accessor: 'type' },
    { Header: 'Usage Limit ', accessor: 'limit' },
    
    // {
    //     Header: 'Status',

    //     Cell: () => (
    //         <Dropdown>
    //             <Dropdown.Toggle variant="success" id="dropdown-basic">
    //                 New Lead 1
    //             </Dropdown.Toggle>

    //             <Dropdown.Menu>
    //                 <Dropdown.Item href="#/action-1">Discussion In Progress</Dropdown.Item>
    //                 <Dropdown.Item href="#/action-2">Lead Converted</Dropdown.Item>
    //                 <Dropdown.Item href="#/action-3">Lead Lost</Dropdown.Item>
    //             </Dropdown.Menu>
    //         </Dropdown>

    //     )
    // },
    { Header: 'Action', Cell: () => <button className="btn-sm btn-secondary"><Link to="/edit-enquiries"><i data-feather="edit"></i></Link></button> }
];
const PromoCodes = () => {
    
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns,
            data
        },
        useSortBy
    );



    const renderSortIcons = (column) => {
        if (column.isSorted) {
            return column.isSortedDesc ? (
                <span className="sort-icon">&#9660;</span>
            ) : (
                <span className="sort-icon">&#9650;</span>
            );
        } else {
            return (
                <>
                    <span className="sort-icon">&#9650;</span>
                    <span className="sort-icon">&#9660;</span>
                </>
            );
        }
    };


    return (
        <div className="row">
            <div className="col">
                <div className="card ">
                    <div className="card-body">
                   
                            <h5 className="card-title border-style mb-3 ">Manage Promo Codes</h5>
                 
                        
                            <div className="search__head">
                                <div className="count">
                                    <p>Show</p>
                                    <input type="number" className='form-control' value={10} readOnly />
                                    <p>entries</p>
                                </div>
                                <div className="search">
                                    <span className='me-2'>Search: </span> <input type="text" className='form-control' placeholder='Search here' />
                                    <i data-feather="search"></i>
                                </div>
                            </div>
                     


                  
                        <div >
                            <table {...getTableProps()} className="react-table table-bordered table-striped table-hover">
                                <thead className="thead-light">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    key={column.id}
                                                    className="text-center"
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {column.render('Header')}
                                                        {(column.id !== 'action' && column.id !== 'role') && (
                                                            <span className="ml-2 arrow_alignment">
                                                                {renderSortIcons(column)}
                                                            </span>
                                                        )}
                                                    </div>

                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()} key={cell.column.id}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="default__pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<i data-feather="chevron-right"></i>}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={2}
                                previousLabel={<i data-feather="chevron-left"></i>}
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default PromoCodes