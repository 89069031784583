import React, { useEffect, useRef, useState } from "react";
import ReactQuill from "react-quill";
import { toolbarOptions } from "../constants/config";
import MediaUploader from "../components/media-uploader/MediaUploader";
import Seo from "../components/Seo";
import SortableList from "../commonComponents/sortFiles/SortableList";
import FileUpload from "../commonComponents/FileUpload/FileUpload";
import { ProgressBar } from "react-bootstrap";
import SupportedFormats from "../commonComponents/SupportedFormats/SupportedFormats";
import {
  getCuratedDetail,
  insertCurated,
  updateCurated,
  uploadFile,
} from "../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import {
  curated_details,
  curated_path,
  detail_curated_loading,
  update_curated_loading,
} from "../redux/commonReducer";
import { IMAGE_BASE_URL } from "../constants/configuration";

export default function Curated() {
  const dispatch = useDispatch();

  const editor = useRef();

  const submitLoading = useSelector(update_curated_loading);
  const loading = useSelector(detail_curated_loading);
  const details = useSelector(curated_details);
  const path = useSelector(curated_path);

  const [data, setData] = useState({
    main_title: "",
    main_tagline: "",
    sub_title: "",
    short_description: "",
    long_description: "",
    gallery: [],
    getin_touch_title: "",
    getin_touch_image: "",
    getin_touch_image_alt: "",
    banner1: "",
    banner2: "",
    banner3: "",
    banner1_alt: "",
    banner2_alt: "",
    banner3_alt: "",
    meta_title: "",
    meta_keyword: "",
    meta_description: "",
  });
  const [err, setErr] = useState({});
  const [videoUrl, setVideoUrl] = useState("");
  const [addAttachmentProgress, setAddAttachmentProgress] = useState(0);
  const [imageLoader, setImageLoader] = useState(false);
  const [invalidUpload, setInvalidUpload] = useState(false);
  const [trackId, setTrackId] = useState("");

  useEffect(() => {
    dispatch(getCuratedDetail());
  }, []);

  useEffect(() => {
    if (details?._id) {
      let result = {
        main_title: details?.main_title,
        main_tagline: details?.main_tagline,
        sub_title: details?.sub_title,
        short_description: details?.short_description,
        long_description: details?.long_description,
        getin_touch_title: details?.getin_touch_title,
        getin_touch_image_alt: details?.getin_touch_image_alt,
        banner1_alt: details?.banner1_alt,
        banner2_alt: details?.banner2_alt,
        banner3_alt: details?.banner3_alt,
        meta_title: details?.meta_title,
        meta_keyword: details?.meta_keyword,
        meta_description: details?.meta_description,
        getin_touch_image: details?.getin_touch_image
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.getin_touch_image}`,
              file: null,
              image: details?.getin_touch_image,
            }
          : "",
        banner1: details?.banner1
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.banner1}`,
              file: null,
              image: details?.banner1,
            }
          : "",
        banner2: details?.banner2
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.banner2}`,
              file: null,
              image: details?.banner2,
            }
          : "",
        banner3: details?.banner3
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.banner3}`,
              file: null,
              image: details?.banner3,
            }
          : "",
      };
      if (details?.gallery?.length) {
        let arr = details?.gallery?.map((ele, i) => {
          let obj = {
            type: ele?.type,
            url:
              ele?.type == "video"
                ? ele?.image
                : `${IMAGE_BASE_URL}${path}/${ele?.image}`,
            name: `${ele?.image}`,
            response: `${ele?.image}`,
            id: ele?._id,
            alt: ele?.alt,
          };
          return obj;
        });
        result = {
          ...result,
          gallery: [...arr],
        };
      }
      console.log("22");
      setData({ ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  function getId(url) {
    var regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    var match = url.match(regExp);

    if (match && match[2].length == 11) {
      setVideoUrl("https://www.youtube.com/embed/" + match[2]);
    } else {
      return "error";
    }
  }

  const handleAddVideoUrl = () => {
    if (videoUrl) {
      setErr({
        ...err,
        gallery: "",
      });
      let dataList = { ...data };
      dataList?.gallery?.push({
        // ...ele,
        url: videoUrl,
        response: videoUrl,
        type: "video",
        id: videoUrl + new Date().getTime() + 1,
        alt: "",
      });
      setData(dataList);
      setVideoUrl("");
    }
  };

  const handleDelete = (file, field) => {
    let temp = [...data?.[field]];
    if (temp?.length) {
      temp = temp?.filter((ele) => ele?.id !== file?.id);
      setData({
        ...data,
        [field]: temp,
      });
    }
  };

  const fileSelectedHandler = (e, isInvalid, message, isClosing, field) => {
    if (isInvalid) {
      setInvalidUpload(true);
      setErr({
        ...err,
        [field]: message,
      });
    } else {
      setInvalidUpload(false);
      setErr({
        ...err,
        [field]: "",
      });
      if (e?.some((elem, i) => elem?.metaFile) && !isClosing) {
        setImageLoader(true);
        let formData = new FormData();
        if (trackId?.length == 0) {
          let track_id = Math.floor(1000 + Math.random() * 9000).toString();
          setTrackId(track_id);
          formData.append("track_id", track_id);
        } else {
          formData.append("track_id", trackId);
        }
        e?.filter((el) => el?.metaFile != undefined)?.forEach((item, i) =>
          formData.append(`file`, item?.metaFile)
        );
        dispatch(
          uploadFile(
            formData,
            (res) => {
              let oldFiles = e?.filter((el) => el?.metaFile == undefined);
              let arr = e
                ?.filter((el) => el?.metaFile != undefined)
                ?.map((ele, i) => {
                  return {
                    // ...ele,
                    url: ele?.url,
                    name: ele?.name,
                    response: res?.data[i],
                    id: res?.data[i] + i + 1,
                    alt: "",
                  };
                });
              setErr({
                ...err,
                [field]: "",
              });
              setData((s) => ({
                ...s,
                [field]: [...oldFiles, ...arr],
              }));
              setImageLoader(false);
            },
            (progress) => {
              setAddAttachmentProgress(progress);
            }
          )
        );
      }
    }
  };

  const altChangeHandler = (key, value, index, field, id) => {
    let dataList = { ...data };
    dataList[field][index][key] = value;
    setData(dataList);
    setErr({
      ...err,
      [`${key}_${id}`]: "",
    });
  };

  const removeImage = (type) => {
    setData((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  const singleFileSelectedHandler = (event, field) => {
    const file = event.target.files?.[0];
    setErr({
      ...err,
      [field]: "",
    });
    let formData = new FormData();
    if (trackId?.length == 0) {
      let track_id = Math.floor(1000 + Math.random() * 9000).toString();
      setTrackId(track_id);
      formData.append("track_id", track_id);
    } else {
      formData.append("track_id", trackId);
    }
    formData.append(`file`, file);
    dispatch(
      uploadFile(formData, (res) => {
        setErr({
          ...err,
          [field]: "",
        });
        setData((s) => ({
          ...s,
          [field]: {
            file: file,
            image: res?.data[0],
            preview: URL.createObjectURL(file),
          },
        }));
      })
    );
  };

  const validate = () => {
    let temp = {};
    if (!data?.main_title?.trim()?.length) {
      temp = {
        ...temp,
        main_title: "Please enter main title",
      };
    }
    if (!data?.main_tagline?.trim()?.length) {
      temp = {
        ...temp,
        main_tagline: "Please enter main tagline",
      };
    }
    if (!data?.sub_title?.trim()?.length) {
      temp = {
        ...temp,
        sub_title: "Please enter sub title",
      };
    }
    if (data?.short_description == "") {
      temp = {
        ...temp,
        short_description: "Please select short description",
      };
    }
    if (data?.long_description == "") {
      temp = {
        ...temp,
        long_description: "Please select long description",
      };
    }
    if (data?.gallery?.length == 0) {
      temp = {
        ...temp,
        gallery: "Please upload gallery files",
      };
    } else {
      data?.gallery?.forEach((ele, i) => {
        if (!ele.alt?.trim()?.length) {
          temp[`alt_${ele?.id}`] = "Please enter alt text";
        }
      });
    }
    if (!data?.getin_touch_title?.trim()?.length) {
      temp = {
        ...temp,
        getin_touch_title: "Please enter get in touch title",
      };
    }
    if (!data?.getin_touch_image_alt?.trim()?.length) {
      temp = {
        ...temp,
        getin_touch_image_alt: "Please enter get in touch alt text",
      };
    }
    if (data?.getin_touch_image == "") {
      temp = {
        ...temp,
        getin_touch_image: "Please upload get in touch image",
      };
    }
    if (!data?.banner1_alt?.trim()?.length) {
      temp = {
        ...temp,
        banner1_alt: "Please enter banner alt text",
      };
    }
    if (data?.banner1 == "") {
      temp = {
        ...temp,
        banner1: "Please upload banner image",
      };
    }
    if (!data?.banner2_alt?.trim()?.length) {
      temp = {
        ...temp,
        banner2_alt: "Please enter banner alt text",
      };
    }
    if (data?.banner2 == "") {
      temp = {
        ...temp,
        banner2: "Please upload banner image",
      };
    }
    if (!data?.banner3_alt?.trim()?.length) {
      temp = {
        ...temp,
        banner3_alt: "Please enter banner alt text",
      };
    }
    if (data?.banner3 == "") {
      temp = {
        ...temp,
        banner3: "Please upload banner image",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        main_title: data?.main_title?.trim(),
        sub_title: data?.sub_title?.trim(),
        main_tagline: data?.main_tagline?.trim(),
        short_description: data?.short_description,
        long_description: data?.long_description,
        getin_touch_title: data?.getin_touch_title?.trim(),
        getin_touch_image: data?.getin_touch_image?.image,
        getin_touch_image_alt: data?.getin_touch_image_alt?.trim(),
        gallery: data?.gallery?.map((item, i) => {
          return {
            [`image`]: item?.response,
            [`alt`]: item?.alt?.trim(),
            [`type`]: item?.type ?? "image",
          };
        }),
        banner1: data?.banner1?.image,
        banner1_alt: data?.getin_touch_image_alt?.trim(),
        banner2: data?.banner2?.image,
        banner2_alt: data?.getin_touch_image_alt?.trim(),
        banner3: data?.banner3?.image,
        banner3_alt: data?.getin_touch_image_alt?.trim(),
        track_id: trackId,
        meta_title: data?.meta_title?.trim(),
        meta_keyword: data?.meta_keyword?.trim(),
        meta_description: data?.meta_description?.trim(),
      };
      console.log(raw);
      if (details?._id) {
        raw = {
          ...raw,
          id: details?._id,
        };
        dispatch(
          updateCurated(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertCurated(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      main_title: "",
      main_tagline: "",
      sub_title: "",
      short_description: "",
      long_description: "",
      gallery: [],
      getin_touch_title: "",
      getin_touch_image: "",
      getin_touch_image_alt: "",
      banner1: "",
      banner2: "",
      banner3: "",
      banner1_alt: "",
      banner2_alt: "",
      banner3_alt: "",
      meta_title: "",
      meta_keyword: "",
      meta_description: "",
    });
    dispatch(getCuratedDetail());
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3 ">Add Curated</h5>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Banner Images</label>
                  <div className="row">
                    <div className="col-12 col-md-4">
                      <MediaUploader
                        media={data.banner1}
                        onUpload={(e) =>
                          singleFileSelectedHandler(e, "banner1")
                        }
                        onRemove={() => removeImage("banner1")}
                      />
                      <small id="error" className="error">
                        {err?.banner1}
                      </small>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Type alt text "
                        onChange={(e) =>
                          onChangeHandler("banner1_alt", e?.target?.value)
                        }
                        value={data?.banner1_alt}
                      />
                      <small id="error" className="error">
                        {err?.banner1_alt}
                      </small>
                    </div>
                    <div className="col-12 col-md-4">
                      <MediaUploader
                        media={data.banner2}
                        onUpload={(e) =>
                          singleFileSelectedHandler(e, "banner2")
                        }
                        onRemove={() => removeImage("banner2")}
                      />
                      <small id="error" className="error">
                        {err?.banner2}
                      </small>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Type alt text "
                        onChange={(e) =>
                          onChangeHandler("banner2_alt", e?.target?.value)
                        }
                        value={data?.banner2_alt}
                      />
                      <small id="error" className="error">
                        {err?.banner2_alt}
                      </small>
                    </div>
                    <div className="col-12 col-md-4">
                      <MediaUploader
                        media={data.banner3}
                        onUpload={(e) =>
                          singleFileSelectedHandler(e, "banner3")
                        }
                        onRemove={() => removeImage("banner3")}
                      />
                      <small id="error" className="error">
                        {err?.banner3}
                      </small>
                      <input
                        type="text"
                        className="form-control mt-2"
                        placeholder="Type alt text "
                        onChange={(e) =>
                          onChangeHandler("banner3_alt", e?.target?.value)
                        }
                        value={data?.banner3_alt}
                      />
                      <small id="error" className="error">
                        {err?.banner3_alt}
                      </small>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Main Heading</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("main_title", e?.target?.value)
                    }
                    value={data?.main_title}
                  />
                  <small id="error" className="error">
                    {err?.main_title}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Main Tagline</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("main_tagline", e?.target?.value)
                    }
                    value={data?.main_tagline}
                  />
                  <small id="error" className="error">
                    {err?.main_tagline}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Sub Heading</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("sub_title", e?.target?.value)
                    }
                    value={data?.sub_title}
                  />
                  <small id="error" className="error">
                    {err?.sub_title}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Short Introduction</label>
                  <textarea
                    className="form-control"
                    rows={2}
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("short_description", e?.target?.value)
                    }
                    value={data?.short_description}
                  ></textarea>
                  <small id="error" className="error">
                    {err?.short_description}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Main Introduction</label>
                  <ReactQuill
                    ref={editor}
                    theme="snow"
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    placeholder="type here"
                    onChange={(e) => onChangeHandler("long_description", e)}
                    value={data?.long_description}
                  />
                  <small id="error" className="error">
                    {err?.long_description}
                  </small>
                </div>
              </div>

              <div className="row">
                <div className="row mb-5">
                  <div className="col-12 col-md-6">
                    <div className="d-flex gap-3">
                      <FileUpload
                        style={{
                          marginRight: 5,
                          marginTop: 15,
                        }}
                        className="css-atc-esc2 mt-0 mb-0"
                        label="Add Gallery Images"
                        noLabel
                        buttonLabel="Choose File"
                        accept={["jpg", "jpeg", "png", "svg"]}
                        onChange={(e, isInvalid, message, isClosing) => {
                          setErr((s) => ({
                            ...s,
                            gallery: "",
                          }));
                          fileSelectedHandler(
                            e,
                            isInvalid,
                            message,
                            isClosing,
                            "gallery"
                          );
                        }}
                        field={"gallery"}
                        loader={imageLoader}
                        error={err.gallery}
                        id="gallery"
                        maxFileSizeMB={100}
                        multiple={true}
                        files={data?.gallery}
                        inputSocket={() => {}}
                      />
                      <ProgressBar
                        progress={addAttachmentProgress}
                        width={350}
                      />
                    </div>
                    <SupportedFormats
                      formats={["jpg", "jpeg", "png", "svg"]}
                      maxSize={100}
                    />
                  </div>
                  <div className="col-12 col-md-4 mb-3">
                    <label className="form-label">Add Youtube Videos</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Video URL"
                      onChange={(e) => getId(e.target.value)}
                      value={videoUrl ? videoUrl : ""}
                    />
                    <small id="error" className="error">
                      {err?.videoUrl}
                    </small>
                  </div>
                  <div className="col-12 col-md-2 mb-3 margin-container">
                    <button
                      type="submit"
                      className="btn btn-primary m-2"
                      disabled={videoUrl ? false : true}
                      onClick={handleAddVideoUrl}
                    >
                      Add
                    </button>
                  </div>
                </div>
                {data?.gallery?.length ? (
                  <SortableList
                    setInputs={setData}
                    inputs={data}
                    field="gallery"
                    handleDelete={handleDelete}
                    randomTrackID={trackId ?? ""}
                    err={err}
                    altChangeHandler={altChangeHandler}
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Add Get in touch</h5>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("getin_touch_title", e?.target?.value)
                    }
                    value={data?.getin_touch_title}
                  />
                  <small id="error" className="error">
                    {err?.getin_touch_title}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Image</label>
                  <MediaUploader
                    media={data.getin_touch_image}
                    onUpload={(e) =>
                      singleFileSelectedHandler(e, "getin_touch_image")
                    }
                    onRemove={() => removeImage("getin_touch_image")}
                  />
                  <small id="error" className="error">
                    {err?.getin_touch_image}
                  </small>
                  <input
                    type="text"
                    className="form-control mt-2"
                    placeholder="Type alt text "
                    onChange={(e) =>
                      onChangeHandler("getin_touch_image_alt", e?.target?.value)
                    }
                    value={data?.getin_touch_image_alt}
                  />
                  <small id="error" className="error">
                    {err?.getin_touch_image_alt}
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="card">
          <div className="card-body">
            <Seo data={data} onChangeHandler={onChangeHandler} />
          </div>
        </div>

        <div className="card">
          <div className="card-body ">
            <div className="col-12">
              <button
                type="submit"
                className="btn btn-primary me-3"
                onClick={() => onSubmitHandler()}
              >
                Submit
              </button>
              <button
                type="button"
                className="btn btn-danger"
                onClick={() => onCancelHandler()}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
