

const CommonTitleCard = ({MainHeading}) => {
  return (
    <div className="card">
    <div className="card-body">
        <h5 className="card-title border-style mb-3">{MainHeading}</h5>
        <div className="row">
            <div className="col-12">
                <div className="mb-3">
                    <label className="form-label">Main Heading</label>
                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                </div>
            </div>

            <div className="col-12">
                <div className="mb-3">
                    <label className="form-label">Tag Line</label>
                    <input type="text" className="form-control" rows={2} placeholder="Type here" />
                </div>
            </div>
        </div>
    </div>
</div>

  )
}

export default CommonTitleCard