import React from "react";
import { useDrag, useDrop } from "react-dnd";
import closeIcon from "./ac_close.svg";

const ItemType = "ITEM";

const SortableItem = ({
  file,
  index,
  moveItem,
  handleDelete,
  field,
  err,
  altChangeHandler,
}) => {
  const ref = React.useRef(null);

  const [, drop] = useDrop({
    accept: ItemType,
    drop(draggedItem) {
      if (draggedItem.index !== index) {
        moveItem(draggedItem.index, index);
        draggedItem.index = index;
      }
    },
    hover(draggedItem) {
      // if (draggedItem.index !== index) {
      //   moveItem(draggedItem.index, index);
      //   draggedItem.index = index;
      // }
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemType,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className="grid-item"
      style={{ opacity: isDragging ? 0.5 : 1 }}
    >
      <div className="closeIcon" onClick={() => handleDelete(file, field)}>
        <img className="close" src={closeIcon} alt="" />
      </div>
      {file.type === "mp4" ||
      file.type === "mov" ||
      file.type === "avi" ||
      file.type === "video" ? (
        <div className="video__prev">
          {file.type === "video" ? (
            <iframe src={file.url} frameborder="0" allowfullscreen></iframe>
          ) : (
            <video controls width="100" height="100">
              <source src={file.url} type={`video/${file.type}`}></source>
            </video>
          )}
        </div>
      ) : (
        <img
          src={file?.url}
          alt={file?.name}
          style={{ width: "100%", height: "100px", objectFit: "cover" }}
        />
      )}
      <div className="mt-2">
        <input
          type="text"
          className="form-control"
          placeholder="Enter alt text"
          onChange={(e) =>
            altChangeHandler("alt", e?.target?.value, index, field,file?.id)
          }
          value={file?.alt}
        />
        <small id="error" className="error">
          {err[`alt_${file?.id}`]}
        </small>
      </div>
    </div>
  );
};

export default SortableItem;
