import React, { useEffect, useState } from "react";
import ReactQuill from "react-quill";
import { toolbarOptions } from "../../constants/config";
import {
  getMilestoneDetail,
  insertMilestone,
  updateMilestone,
} from "../../redux/actionCreator";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  detail_payment_milestone_loading,
  payment_milestone_details,
  update_payment_milestone_loading,
} from "../../redux/commonReducer";

const PaymentMilestones = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector(detail_payment_milestone_loading);
  const submitLoading = useSelector(update_payment_milestone_loading);
  const details = useSelector(payment_milestone_details);

  const [data, setData] = useState({
    title: "",
    milestone_percentage: "",
    milestone_description: "",
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getMilestoneDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        milestone_name: details?.milestone_name,
        milestone_percentage: details?.milestone_percentage,
        milestone_description: details?.milestone_description,
      };
      setData({ ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const documentUploadHandler = (field, e, isInvalid) => {
    if (isInvalid) {
      setErr({
        ...err,
        [field]: "Invalid file format",
      });
    } else {
      setErr({
        ...err,
        [field]: "",
      });
      setData({
        ...data,
        [field]: [
          {
            type: e[0].type,
            url: e[0].url,
            name: e[0]?.name,
            metaFile: e,
            response: e,
          },
        ],
      });
      setErr({
        ...err,
        [field]: "",
      });
    }
  };

  const validate = () => {
    let temp = {};
    if (!data?.milestone_name?.trim()?.length) {
      temp = {
        ...temp,
        milestone_name: "Please enter milestone name",
      };
    }
    if (!data?.milestone_percentage?.trim()?.length) {
      temp = {
        ...temp,
        milestone_percentage: "Please enter milestone percentage",
      };
    }
    if (data?.milestone_description == "") {
      temp = {
        ...temp,
        milestone_description: "Please select milestone description",
      };
    }
    if (data?.image == "") {
      temp = {
        ...temp,
        image: "Please upload image",
      };
    }
    if (data?.is_billable == "") {
      temp = {
        ...temp,
        is_billable: "Please choose billable value",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        milestone_name: data?.milestone_name?.trim(),
        milestone_description: data?.milestone_description?.trim(),
        milestone_percentage: data?.milestone_percentage?.trim(),
      };

      console.log(raw);
      if (id) {
        raw = {
          ...raw,
          id: id,
        };
        dispatch(
          updateMilestone(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertMilestone(raw, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      milestone_name: "",
      milestone_percentage: "",
      milestone_description: "",
      is_billable: "",
    });
    navigate("/payment-milestone");
  };
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Payment Milestone</h5>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label htmlFor="milestoneInput" className="form-label">
                    Milestone Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="milestoneInput"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("milestone_name", e?.target?.value)
                    }
                    value={data?.milestone_name}
                  />
                  <small id="error" className="error">
                    {err?.milestone_name}
                  </small>
                </div>
              </div>
              <div className="col-12 ">
                <div className="mb-3">
                  <label htmlFor="percentageInput" className="form-label">
                    Percentage (%)
                  </label>
                  <input
                    type="number"
                    className="form-control typeNumber"
                    id="percentageInput"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("milestone_percentage", e?.target?.value)
                    }
                    value={data?.milestone_percentage}
                  />
                  <small id="error" className="error">
                    {err?.milestone_percentage}
                  </small>
                </div>
              </div>
              <div className="col-12 ">
                <div className="mb-3">
                  <label className="form-label">Description</label>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    placeholder="type here"
                    onChange={(e) =>
                      onChangeHandler("milestone_description", e)
                    }
                    value={data?.milestone_description}
                  />
                  <small id="error" className="error">
                    {err?.milestone_description}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary m-2"  onClick={() => onSubmitHandler()}>
                  Submit
                </button>
                <button type="button" className="btn btn-danger" onClick={() => onCancelHandler()}>
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMilestones;
