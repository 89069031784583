import React from 'react'

const StatusCard = () => {
    return (

        <div className="card">
            <div className="card-body">
                <div className="row">
                    <div className="col-12">
                        <h4 className="card-title border-style mb-3">Status</h4>
                        <fieldset className="row mb-3">
                            <div className="col-sm-10 d-flex gap-10" style={{ gap: "20px" }}>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="gridRadios" id="yes" value="yes" checked />
                                    <label className="form-check-label" for="yes">
                                        Active
                                    </label>
                                </div>
                                <div className="form-check">
                                    <input className="form-check-input" type="radio" name="no" id="gridRadios2" value="no" />
                                    <label className="form-check-label" for="no">
                                        Inactive
                                    </label>
                                </div>
                            </div>
                        </fieldset>
                    </div>

                    <div className="col-12">
                        <button type="submit" className="btn btn-primary me-3">Submit</button>
                        <button type="submit" className="btn btn-danger">Cancel</button>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default StatusCard