import React from "react";
import ReactQuill from "react-quill";
import { toolbarOptions } from "../constants/config";

const Seo = ({ data, onChangeHandler }) => {
  return (
    <>
      <h5 className="card-title border-style mb-3">SEO</h5>
      <div className="row">
        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label className="form-label">Meta Title</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type here"
              onChange={(e) => onChangeHandler("meta_title", e?.target?.value)}
              value={data?.meta_title}
            />
          </div>
        </div>

        <div className="col-12 col-md-6">
          <div className="mb-3">
            <label className="form-label">Meta Keywords</label>
            <input
              type="text"
              className="form-control"
              placeholder="Type here"
              onChange={(e) => onChangeHandler("meta_keyword", e?.target?.value)}
              value={data?.meta_keyword}
            />
          </div>
        </div>

        <div className="col-12">
          <div className="mb-3">
            <label className="form-label">Meta description</label>
            <ReactQuill
              theme="snow"
              modules={{
                toolbar: toolbarOptions,
              }}
              placeholder="Type here"
              onChange={(e) => onChangeHandler("meta_description", e)}
              value={data?.meta_description}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Seo;
