import React from 'react'
import Seo from '../components/Seo'
import MediaUploader from '../components/media-uploader/MediaUploader'


const Contact = () => {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3"> Contact Us Page</h5>
                        <div className="row">
                            {/* <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div> */}
                            <div className="col-12 col-md-6 mb-3">
                                <label className="form-label">Banner Image</label>
                                <MediaUploader />
                            </div>


                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3"> Contact Us </h5>
                        <div className="row">
                            {/* <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div> */}
                            <div className="col-12  mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Email Description</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Email </label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Phone Description</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Phone </label>
                                    <input type="number" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <div className="mb-3">
                                    <label className="form-label">USA Address Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <div className="mb-3">
                                    <label className="form-label">UAE Address Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12  ">
                                <div className="mb-3">
                                    <label className="form-label">USA Address</label>
                                    <textarea className='form-control ' rows='2' placeholder='Type here'></textarea>

                                </div>
                            </div>


                            <div className="col-12  mb-3">
                                <div className="mb-3">
                                    <label className="form-label">UAE Address</label>
                                    <textarea className='form-control ' rows='2' placeholder='Type here'></textarea>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>


                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3"> Get In Touch</h5>
                        <div className="row">

                            <div className="col-12  mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <div className="mb-3">
                                    <label className="form-label"> Tag Line</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>

                            <div className="col-12 col-md-6 mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Footer Info Text</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>



                        </div>
                    </div>
                </div>


                <div className="card">
                    <div className="card-body">
                        <Seo />
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary m-2">Submit</button>
                            <button type="submit" className="btn btn-danger">Cancel</button>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    )
}

export default Contact