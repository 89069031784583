import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "../../assets/icons/EDIT.svg";
import {
  child_cost_details,
  detail_child_cost_loading,
  update_child_cost_loading,
} from "../../redux/commonReducer";
import { getChildCostDetail, insertChildCost } from "../../redux/actionCreator";

const CostForChild = () => {
  const dispatch = useDispatch();

  const submitLoading = useSelector(update_child_cost_loading);
  const detailLoading = useSelector(detail_child_cost_loading);
  const details = useSelector(child_cost_details);

  const [data, setData] = useState({
    age_less_2: "",
    age_2_5: "",
    age_5_12: "",
    age_less_2_edit: false,
    age_2_5_edit: false,
    age_5_12_edit: false,
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    dispatch(getChildCostDetail());
  }, []);

  useEffect(() => {
    if (details) {
      setData({
        age_less_2: details?.age_less_2,
        age_2_5: details?.age_2_5,
        age_5_12: details?.age_5_12,
        age_less_2_edit: false,
        age_2_5_edit: false,
        age_5_12_edit: false,
      });
    }
  }, [details]);

  const validate = () => {
    let temp = {};
    if (!data?.age_less_2?.trim()?.length) {
      temp = {
        ...temp,
        age_less_2: "Please enter value",
      };
    }
    if (!data?.age_2_5?.trim()?.length) {
      temp = {
        ...temp,
        age_2_5: "Please enter value",
      };
    }
    if (!data?.age_5_12?.trim()?.length) {
      temp = {
        ...temp,
        age_5_12: "Please enter value",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };
  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        age_less_2: data?.age_less_2?.trim(),
        age_2_5: data?.age_2_5?.trim(),
        age_5_12: data?.age_5_12?.trim(),
      };
      console.log(raw);
      dispatch(
        insertChildCost(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setData({
      age_less_2: "",
      age_2_5: "",
      age_5_12: "",
      age_less_2_edit: false,
      age_2_5_edit: false,
      age_5_12_edit: false,
    });
    dispatch(getChildCostDetail());
  };

  const onChangeHandler = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  return (
    console.log(data),
    (
      <>
        <div className="row">
          <div className="col">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Cost For Child</h5>
                <div className="row mt-3">
                  <div className="col-12 col-md-7">
                    <div className="mb-3">
                      <label htmlFor={`ageGroupInput1`} className="form-label">
                        Age less than 2 years (%)
                      </label>
                      <input
                        type="number"
                        className="form-control typeNumber"
                        id={`ageGroupInput1`}
                        placeholder="Type here"
                        value={data?.age_less_2}
                        disabled={!data?.age_less_2_edit}
                        onChange={(e) =>
                          onChangeHandler("age_less_2", e?.target?.value)
                        }
                        max={100}
                      />
                      <small id="error" className="error">
                        {err?.age_less_2}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-md-1">
                    <div className="mb-3">
                      <div className="actions preview-imagemargin">
                        <button
                          className="btn-sm btn-primary"
                          onClick={() =>
                            onChangeHandler("age_less_2_edit", true)
                          }
                        >
                          <img
                            src={EditIcon}
                            alt=""
                            style={{
                              height: "31px",
                              width: "35px",
                              marginRight: "0px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="mb-3">
                      <label htmlFor={`ageGroupInput2`} className="form-label">
                        Age between 2 to 5 years (%)
                      </label>
                      <input
                        type="number"
                        className="form-control typeNumber"
                        id={`ageGroupInput2`}
                        placeholder="Type here"
                        value={data?.age_2_5}
                        disabled={!data?.age_2_5_edit}
                        onChange={(e) =>
                          onChangeHandler("age_2_5", e?.target?.value)
                        }
                      />
                      <small id="error" className="error">
                        {err?.age_2_5}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-md-1">
                    <div className="mb-3">
                      <div className="actions preview-imagemargin">
                        <button
                          className="btn-sm btn-primary"
                          onClick={() => onChangeHandler("age_2_5_edit", true)}
                        >
                          <img
                            src={EditIcon}
                            alt=""
                            style={{
                              height: "31px",
                              width: "35px",
                              marginRight: "0px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12 col-md-7">
                    <div className="mb-3">
                      <label htmlFor={`ageGroupInput3`} className="form-label">
                        Age between 5 to 12 years (%)
                      </label>
                      <input
                        type="number"
                        className="form-control typeNumber"
                        id={`ageGroupInput3`}
                        placeholder="Type here"
                        value={data?.age_5_12}
                        disabled={!data?.age_5_12_edit}
                        onChange={(e) =>
                          onChangeHandler("age_5_12", e?.target?.value)
                        }
                      />
                      <small id="error" className="error">
                        {err?.age_5_12}
                      </small>
                    </div>
                  </div>
                  <div className="col-12 col-md-1">
                    <div className="mb-3">
                      <div className="actions preview-imagemargin">
                        <button
                          className="btn-sm btn-primary"
                          onClick={() => onChangeHandler("age_5_12_edit", true)}
                        >
                          <img
                            src={EditIcon}
                            alt=""
                            style={{
                              height: "31px",
                              width: "35px",
                              marginRight: "0px",
                            }}
                          />
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <button
                    type="submit"
                    className="btn btn-primary m-2"
                    onClick={() => onSubmitHandler()}
                  >
                    Submit
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger"
                    onClick={() => onCancelHandler()}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default CostForChild;
