import React, { useEffect, useState } from 'react';
import PerfectScrollbar from 'perfect-scrollbar';
import feather from 'feather-icons';
import { NavLink, useLocation } from 'react-router-dom';

const SideBar = () => {
    const location = useLocation();
    const [openIndex, setOpenIndex] = useState(null);

    useEffect(() => {
        const sidebar = document.querySelector('.page-sidebar');
        if (sidebar) {
            new PerfectScrollbar(sidebar);
        }
        feather.replace();


        menuItems.forEach((section, sectionIndex) => {
            section.items.forEach((item, index) => {
                const currentIndex = `${section.id}-${index}`;
                if (item.to === location.pathname || (item.children && item.children.some(child => child.to === location.pathname))) {
                    setOpenIndex(currentIndex);
                }
            });
        });
    }, []);

    const handleAccordionClick = (index) => {
        setOpenIndex(prevIndex => (prevIndex === index ? null : index));
    };

    const menuItems = [
        {
            id: 1, title: 'Main', items: [
                { to: '/', icon: 'layout', label: 'Dashboard' },
                {
                    to: '/manage-blogs',
                    icon: 'copy',
                    label: 'Blogs',

                },
                {
                    to: '/manage-bookings',
                    icon: 'copy',
                    label: 'Manage Booking',

                },
                {
                    to: '/manage-enquiries',
                    icon: 'copy',
                    label: 'Enquiries',

                }

            ]
        },

        {
            id: 2, title: 'Sub Menu', items: [
                { to: '/manage-packages', icon: 'briefcase', label: 'Packages' },
                { to: '/manage-reviews', icon: 'star', label: 'Reviews' },
                { to: '/manage-promocodes', icon: 'star', label: 'Promo Codes' },
                { to: '/manage-reviews', icon: 'star', label: 'Customer Search' },
                {
                    to: '#',
                    icon: 'plus-square',
                    label: 'CMS',
                    children: [
                        { to: '/home', icon: 'circle', label: 'Home' },
                        { to: '/about', icon: 'circle', label: 'About' },
                        { to: '/curated', icon: 'circle', label: 'Curated' },
                        {to:'/package-page', icon:'circle',label:'Package Page'},
                        { to: '/contact', icon: 'circle', label: 'Contact' },
                        { to: '/terms-condition', icon: 'circle', label: 'Terms & Condition' },
                        { to: '/booking-process', icon: 'circle', label: 'Booking Process' },
                        { to: '/cancel-policy', icon: 'circle', label: 'Cancel Policy' },
                        { to: '/privacy-policy', icon: 'circle', label: 'Policy' },
                        { to: '/general-page', icon: 'circle', label: 'General' },
                    ]
                },





                {
                    to: '/manage-permission',
                    icon: 'user-check',
                    label: 'User Permissions',

                },



            ]
        },
        {
            id: 3, title: 'Settings', items: [

                { to: '/manage-flights', icon: 'plus', label: 'Flight Settings' },

                {
                    to: '#',
                    icon: 'plus-square',
                    label: ' Package Settings',
                    children: [
                        { to: '/manage-season', icon: 'circle', label: 'Manage Seasons' },
                        { to: '/manage-attraction', icon: 'circle', label: 'Manage Attractions' },
                        { to: '/manage-activity', icon: 'circle', label: 'Manage Activities' },
                        { to: '/manage-essential', icon: 'circle', label: 'Essential Information' },
                        { to: '/manage-inclusions', icon: 'circle', label: 'Manage Inclusions' },
                        { to: '/manage-exclusions', icon: 'circle', label: 'Manage Exclusions' },
                        { to: '/vehicle-info', icon: 'circle', label: 'Vehicle Experience' },
                        { to: '/hotel-experiance', icon: 'circle', label: 'Hotel Experience' },
                        { to: '/questionnaire', icon: 'circle', label: 'Questionnaires' },
                    ]
                },
                {
                    to: '#',
                    icon: 'dollar-sign',
                    label: 'Cost Settings',
                    children: [
                        { to: '/room', icon: 'circle', label: 'Room Costing' },
                        { to: '/vehicle', icon: 'circle', label: 'Vehicle Costing' },
                        { to: '/cost-child', icon: 'circle', label: 'Cost for Child' },
                        { to: '/flight-markup', icon: 'circle', label: 'Flight Cost' },
                        { to: '/payment-milestone', icon: 'circle', label: 'Payment Milestones' },
                        { to: '/currency-conversion', icon: 'circle', label: 'Currency Conversions' },
                    ]
                },
            ]
        }

    ]


    return (
        <div className="page-sidebar">
            <ul className="list-unstyled accordion-menu">
                {menuItems.map((section, sectionIndex) => (
                    <React.Fragment key={section.id}>
                        <li className="sidebar-title">{section.title}</li>
                        {section.items.map((item, index) => {
                            const currentIndex = `${section.id}-${index}`;
                            return (
                                <li key={currentIndex} className={`${openIndex === currentIndex ? 'active-page open' : ''}`}>
                                    <NavLink to={item.to} onClick={() => handleAccordionClick(currentIndex)}>
                                        <i data-feather={item.icon}></i>{item.label}
                                        {item.children && (
                                            <i className="arrow-right" data-feather="chevron-right"></i>
                                        )}
                                    </NavLink>
                                    {item.children && (
                                        <ul className={`submenu ${openIndex === currentIndex ? 'navEffect' : ''}`} style={{ display: openIndex === currentIndex ? 'block' : 'none' }}>
                                            {item.children.map((child, childIndex) => (
                                                <li key={`${currentIndex}-${childIndex}`}>
                                                    <NavLink to={child.to}>
                                                        <i data-feather={child.icon}></i>{child.label}
                                                    </NavLink>
                                                </li>
                                            ))}
                                        </ul>
                                    )}
                                </li>
                            );
                        })}
                    </React.Fragment>
                ))}
            </ul>
        </div>
    );
};

export default SideBar;
