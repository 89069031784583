import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { useDispatch, useSelector } from "react-redux";
import {
  getSeasonDetail,
  insertSeason,
  updateSeason,
} from "../../redux/actionCreator";
import { useNavigate, useParams } from "react-router-dom";
import {
  detail_season_loading,
  season_details,
  season_path,
  update_season_loading,
} from "../../redux/commonReducer";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import MediaUploader from "../../components/media-uploader/MediaUploader";

export default function Seasons() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector(detail_season_loading);
  const details = useSelector(season_details);
  const path = useSelector(season_path);
  const submitLoading = useSelector(update_season_loading);

  const [data, setData] = useState({
    name: "",
    icon: "",
    start_date: "",
    end_date: "",
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getSeasonDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        name: details?.name,
        start_date: details?.start_date,
        end_date: details?.end_date,
        // icon: details?.icon
        //   ? [
        //       {
        //         type: details?.icon?.split(".").pop(),
        //         url: `${IMAGE_BASE_URL}${path}/${details?.icon}`,
        //         name: `${details?.icon}`,
        //         response: `${details?.icon}`,
        //       },
        //     ]
        //   : "",
        icon: details?.icon
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.icon}`,
              file: null,
              image: details?.icon,
            }
          : "",
        icon_alt: details?.icon_alt,
      };
      setData({ ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const documentUploadHandler = (field, e, isInvalid) => {
    if (isInvalid) {
      setErr({
        ...err,
        [field]: "Invalid file format",
      });
    } else {
      setErr({
        ...err,
        [field]: "",
      });
      setData({
        ...data,
        [field]: [
          {
            type: e[0].type,
            url: e[0].url,
            name: e[0]?.name,
            metaFile: e,
            response: e,
          },
        ],
      });
      setErr({
        ...err,
        [field]: "",
      });
    }
  };

  const validate = () => {
    let temp = {};
    if (!data?.name?.trim()?.length) {
      temp = {
        ...temp,
        name: "Please enter name",
      };
    }
    if (!data?.icon_alt?.trim()?.length) {
      temp = {
        ...temp,
        icon_alt: "Please enter alt text",
      };
    }
    if (data?.start_date == "") {
      temp = {
        ...temp,
        start_date: "Please select start date",
      };
    }
    if (data?.end_date == "") {
      temp = {
        ...temp,
        end_date: "Please select end date",
      };
    }
    if (data?.icon == "") {
      temp = {
        ...temp,
        icon: "Please upload icon",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let formData = new FormData();
      formData.append("name", data?.name?.trim());
      formData.append("start_date", data?.start_date);
      formData.append("end_date", data?.end_date);
      formData.append("icon", data?.icon?.file ?? data?.icon?.image);
      formData.append("icon_alt", data?.icon_alt?.trim());
      console.log(formData);
      if (id) {
        formData.append("id", id);
        dispatch(
          updateSeason(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertSeason(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      name: "",
      icon_alt: "",
      icon: "",
      start_date: "",
      end_date: "",
    });
    navigate("/manage-season");
  };

  const handleImageUpload = (event, type) => {
    const file = event.target.files?.[0];
    if (file) {
      setData((prevState) => ({
        ...prevState,
        [type]: { file, preview: URL.createObjectURL(file) },
      }));
    }
  };

  const removeImage = (type) => {
    setData((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Add Season</h5>
            <div className="row mt-3">
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Name</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={(e) => onChangeHandler("name", e?.target?.value)}
                    value={data?.name}
                  />
                  <small id="error" className="error">
                    {err?.name}
                  </small>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Start Date</label>
                  <DatePicker
                    className="form-control"
                    onChange={(date) => onChangeHandler("start_date", date)}
                    selected={data?.start_date}
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    minDate={new Date()}
                    showMonthDropdown
                    showYearDropdown
                  />
                  <small id="error" className="error">
                    {err?.start_date}
                  </small>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">End Date</label>
                  <DatePicker
                    className="form-control"
                    onChange={(date) => onChangeHandler("end_date", date)}
                    selected={data?.end_date}
                    placeholder="DD/MM/YYYY"
                    dateFormat="dd/MM/yyyy"
                    minDate={data?.start_date}
                    showMonthDropdown
                    showYearDropdown
                  />
                  <small id="error" className="error">
                    {err?.end_date}
                  </small>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Upload icon</label>
                  <MediaUploader
                    media={data.icon}
                    onUpload={(e) => handleImageUpload(e, "icon")}
                    onRemove={() => removeImage("icon")}
                  />
                  <small id="error" className="error">
                    {err?.icon}
                  </small>
                </div>

                {/* <FileUpload
                  className="css-atc-esc2"
                  label={"Upload icon"}
                  buttonLabel="Choose file"
                  accept={["jpg", "jpeg", "png", "svg"]}
                  onChange={(e, isInvalid) =>
                    documentUploadHandler("icon", e, isInvalid)
                  }
                  error={err.icon}
                  id="icon"
                  files={data?.icon}
                  // required
                  inputSocket={() => {}}
                /> */}
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Add Alt Text</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("icon_alt", e?.target?.value)
                    }
                    value={data?.icon_alt}
                  />
                  <small id="error" className="error">
                    {err?.icon_alt}
                  </small>
                </div>
              </div>

              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary m-2"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
