import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useFilter from "../../utils/Hooks/useFilter";
import {
  flight_data,
  flight_page_details,
  flight_path,
} from "../../redux/commonReducer";
import { deleteFlight, getFlight } from "../../redux/actionCreator";
import feather from "feather-icons";
import EntriesCounter from "../../commonComponents/EntriesCounter/EntriesCounter";
import Input from "../../commonComponents/Input/Input";
import Table from "../../commonComponents/Table/Table";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import Actions from "../../commonComponents/Actions/Actions";
import { Modal } from "react-bootstrap";
import EditIcon from "../../assets/icons/EDIT.svg";
import DeleteIcon from "../../assets/icons/DELETE.svg";
import DeleteModal from "../../commonComponents/DeleteModal/DeleteModal";

const ManageFlights = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const data = useSelector(flight_data);
  const pageData = useSelector(flight_page_details);
  const path = useSelector(flight_path);

  const initialFilter = {
    page: 0,
    perPage: 10,
    sortColumn: "updated_at",
    sortValue: -1,
    keyword: "",
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteID, setDeleteID] = useState("");

  const [filterData, setFilterData, F_navigate] = useFilter({
    initialFilter,
    dataFetch: (filter) => {
      getSearchDataHandler(filter);
    },
    dependency: [],
  });

  useEffect(() => {
    feather.replace();
  }, []);

  useEffect(() => {
    getSearchDataHandler(initialFilter);
  }, []);

  const getSearchDataHandler = (filter) => {
    let formData = new FormData();
    formData.append("page", filter.page);
    formData.append("perPage", filter.perPage);
    formData.append("sort_order", filter.sortValue);
    formData.append("sort_key", filter.sortColumn);
    formData.append("keyword", filter?.keyword?.trim() ?? "");
    dispatch(getFlight(formData));
    console.log(formData);
  };

  const showDeletePopup = (id) => {
    setShowDeleteModal(true);
    setDeleteID(id);
  };

  function filterHandler(key, value) {
    let filter;
    switch (key) {
      case "searchBox":
        filter = { ...filterData, ...value, page: 0 };
        break;
      case "clearSearch":
        filter = initialFilter;
        break;
      case "keyword":
        filter = { ...filterData, keyword: value, page: 0 };
        break;
      case "sort":
        filter = {
          ...filterData,
          sortColumn: value.key,
          sortValue: value.value,
        };
        break;
      case "page":
        filter = { ...filterData, page: value };
        break;
      case "entries":
        filter = { ...filterData, page: 0, perPage: parseInt(value) };
        break;
      default:
    }

    if (!filter) return;
    setFilterData(filter);
    getSearchDataHandler(filter);
  }

  const handleClose = () => {
    setDeleteID("");
    setShowDeleteModal(false);
  };

  const deleteHandler = () => {
    dispatch(
      deleteFlight(deleteID, () => {
        setShowDeleteModal(false);
        setDeleteID("");
        if (data?.length == 1 && pageData?.pages > 1 && filterData?.page != 0) {
          setFilterData({
            ...filterData,
            page: filterData?.page - 1,
          });
          let formData = new FormData();
          formData.append("page", filterData?.page - 1);
          formData.append("perPage", filterData.perPage);
          formData.append("sort_order", filterData.sortValue);
          formData.append("sort_key", filterData.sortColumn);
          formData.append("keyword", filterData?.keyword?.trim() ?? "");
          dispatch(getFlight(formData));
        } else {
          getSearchDataHandler(filterData);
        }
      })
    );
  };
  return (
    <>
      <div className="row">
        <div className="col">
          <div className="card">
            <div className="card-body">
              <div className="table__add border-style">
                <h5 className="card-title">Manage Flights</h5>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/add-flight")}
                >
                  Add Flights
                </button>
              </div>
              <div className="search__head">
                <div className="count">
                  <EntriesCounter
                    value={filterData.perPage}
                    onChange={(e) => {
                      filterHandler("entries", e);
                    }}
                  />
                </div>
                <div className="search">
                  <Input
                    value={filterData.keyword}
                    type="text"
                    className="css-inp-esc2 search"
                    // icon={GenericSearchIcon}
                    placeholder={"Search Titles"}
                    reverse
                    width="300px"
                    field="keyword"
                    showCloseBtn
                    onChange={(e) => !e && filterHandler("keyword", e)}
                    onSubmit={(e) => {
                      filterHandler("keyword", e);
                    }}
                    onIconClick={(e) => {
                      filterHandler("keyword", e);
                    }}
                  />
                </div>
              </div>
              <Table
                titles={[
                  {
                    title: "Sl.No",
                    key: "sl_no",
                    width: "10px",
                  },
                  {
                    title: "Logo",
                    key: "logo",
                    sort: true,
                    width: "300px",
                  },
                  {
                    title: "Flight Name",
                    key: "flight_name",
                    sort: true,
                    width: "200px",
                  },
                  {
                    title: "Flight Code",
                    key: "flight_code",
                    sort: true,
                    width: "100px",
                  },
                  {
                    title: "Enabled API search ",
                    key: "is_airline_include",
                    sort: true,
                    width: "100px",
                  },

                  { title: "Action", key: "action", width: "100px" },
                ]}
                content={data?.map((item, i) => ({
                  sl_no: filterData.page * filterData.perPage + (i + 1),
                  flight_name: item?.flight_name,
                  logo: (
                    <img
                      src={`${IMAGE_BASE_URL}${path}/${item?.logo}`}
                      alt=""
                    />
                  ),
                  flight_code: item?.flight_code,
                  is_airline_include: item?.is_airline_include ? "Yes" : "No",
                  action: (
                    <Actions
                      buttons={{
                        edit: EditIcon,
                        delete: DeleteIcon,
                      }}
                      onEdit={() => F_navigate(`/update-flight/${item?._id}`)}
                      onDelete={() => showDeletePopup(item?._id)}
                    />
                  ),
                }))}
                onSort={(e) => filterHandler("sort", e)}
                page={filterData.page}
                pagesCount={pageData?.pages}
                pageStatus={pageData?.status}
                onPageChange={(e) => {
                  filterHandler("page", e.selected);
                }}
                sortValue={{
                  key: filterData.sortColumn,
                  value: filterData.sortValue,
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <DeleteModal
        showDeleteModal={showDeleteModal}
        handleClose={handleClose}
        deleteHandler={deleteHandler}
        title={"Delete Flight"}
      />
    </>
  );
};

export default ManageFlights;
