import React, { useState } from 'react'
import IconUploader from './media-uploader/IconUploader';

const PackageFooter = ({title}) => {
    const [fields, setFields] = useState([{ icon: null, description: '' }]);
  

    const handleRemoveField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };


    const handleAddField = () => {
        setFields([...fields, { icon: null, description: '',title:"",alt_icon:"" }]);
    };
    return (
 
    <div>
        <h5 className="card-title border-style mb-3">{title} </h5>
        
            <div className="col-12 mb-3 border-style">
                <label className="form-label ">Title</label>
                <input type="text" className="form-control" placeholder="Type here" />

            </div>

           
                {fields.map((field, index) => (
                    <div key={index} className="row mb-3">
                    
                        <div className="col-12 col-md-2 mb-3">
                            <IconUploader
                                index={index}
                            />
                        </div>
                        <div className="col-12 col-md-5">
                            <label className="form-label">{index + 1}.Title</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Type here"
                                value={field.title}

                            />
                        </div>

                        <div className="col-12 col-md-5">
                            <label className="form-label">{index + 1}.Alt Icon</label>
                            <input
                                type="text"
                                className="form-control"
                                placeholder="Type here"
                                value={field.alt_icon}

                            />
                        </div>
                        <div className="col-12 mb-3 ">
                            <label className="form-label">Description</label>
                            <textarea className='form-control' value={field.description} rows='2' placeholder='Type here'></textarea>
                        </div>
                        <div className="col-12 d-flex justify-content-end ">
                            {index === fields.length - 1 ? (
                                <button className="btn btn-primary " onClick={handleAddField}>
                                    Add
                                </button>
                            ) : (
                                <button className="btn btn-danger " onClick={() => handleRemoveField(index)}>
                                    Delete
                                </button>
                            )}
                        </div>
                        </div>
               
                ))}
        
            <div className="row">

            
            <div className="col-6 mb-3">
                <label className="form-label ">Short Title</label>
                <input type="text" className="form-control" placeholder="Type here" />

            </div>
            <div className="col-6 mb-3">
                <label className="form-label ">Short Description</label>
                <input type="text" className="form-control" placeholder="Type here" />

            </div>
            </div>
        </div>
 
  )
}

export default PackageFooter