import React from "react";
import { Route, Routes } from "react-router-dom";
import Dashboard from "../pages/Dashboard";
import HotelRooms from "../pages/Cost Settings/HotelRooms";
import FlightMarkup from "../pages/Cost Settings/FlightMarkup";
import Activities from '../pages/Activity/Activities'
import ManageEssential from "../pages/Essential Info/ManageEssential";
import AddEssential from "../pages/Essential Info/AddEssential";
import ManageActivity from "../pages/Activity/ManageActivity";
import ManageAttraction from "../pages/Attractions/ManageAttraction";
import ManageInclusions from "../pages/Settings/ManageInclusions";
import ManageExclusions from "../pages/Settings/ManageExclusions";
import Accommodation from "../pages/Accommodation";
import Questionnaire from "../pages/Questionnaire";
import Layout from "../components/Layout/Layout";
import Login from "../pages/Login/Login";
import AddPermission from "../pages/Permission/AddPermission";
import ManagePermissions from "../pages/Permission/ManagePermissions";
import Blog from "../pages/Blogs/Blog";
import ManageBlogs from "../pages/Blogs/ManageBlogs";
import Vehicle from "../pages/Cost Settings/Vehicle";
import Seasons from "../pages/Seasons/Seasons";
import ManageSeasons from "../pages/Seasons/ManageSeasons";
import About from "../pages/About";
import Curated from "../pages/Curated";
import VehicleInfo from "../pages/Vehicle Info/VehicleInfo";

import AddDescription from "../pages/Essential Info/AddDescription";
import ManagePackages from "../pages/Packages/ManagePackages";
import AddPackages from "../pages/Packages/AddPackages";
import Home from "../pages/Home/Home";
import ManageReviews from "../pages/Reviews/ManageReviews";
import ManageFlights from "../pages/Flight Settings/ManageFlights";
import AddVehicle from "../pages/Vehicle Info/AddVehicle";
import AddPartners from "../pages/Partners Info/AddPartners";
import HotelExperiance from "../pages/Hotel Experiance/HotelExperiance";
import CostForChild from "../pages/Cost Settings/CostForChild";
import PaymentMilestones from "../pages/Payment Mile Stone/PaymentMilestones";
import CurrencyConversions from "../pages/Currency Conversion/CurrencyConversions";
import ManagePayment from "../pages/Payment Mile Stone/ManagePayment";
import ManageCurrrencyConversion from "../pages/Currency Conversion/ManageCurrrencyConversion";
import ManageEnquiries from "../pages/Enquiries/ManageEnquiries";

import PrivateRoutes from "../utils/PrivateRoutes";
import Attractions from "../pages/Attractions/Attractions";
import EditEnquiries from "../pages/Enquiries/EditEnquiries";
import AddFlights from "../pages/Flight Settings/AddFlights";
import TermsAndCondition from "../pages/TermsAndCondition";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import BookingProcess from "../pages/BookingProcess";
import CancellationPolicy from "../pages/CancellationPolicy";
import ManageBookings from "../pages/Bookings/ManageBookings";
import ViewBooking from "../pages/Bookings/ViewBooking";
import Contact from "../pages/Contact";
import PromoCodes from "../pages/PromoCodes";

import GeneralPage from "../pages/GeneralPage";
import PackagePage from "../pages/PackagePage";

function RouteHandler() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route element={<PrivateRoutes />}>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<Dashboard />} />
          
          <Route path="/vehicle" element={<Vehicle />} />
          <Route path="/manage-reviews" element={<ManageReviews />} />
         
          <Route path="/add-description" element={<AddDescription />} />
          <Route path="/accommodation" element={<Accommodation />} />
          <Route path="/add-permission" element={<AddPermission />} />
          <Route path="/manage-permission" element={<ManagePermissions />} />
          <Route path="/add-blog" element={<Blog />} />
          <Route path="/manage-blogs" element={<ManageBlogs />} />

          <Route path="/add-season" element={<Seasons />} />
          <Route path="/manage-season" element={<ManageSeasons />} />
          <Route path="/update-season/:id" element={<Seasons />} />

          <Route path="/manage-attraction" element={<ManageAttraction />} />
          <Route path="/add-attraction" element={<Attractions />} />
          <Route path="/update-attraction/:id" element={<Attractions />} />

          <Route path="/manage-activity" element={<ManageActivity />} />
          <Route path="/add-activity" element={<Activities />} />
          <Route path="/update-activity/:id" element={<Activities />} />

          <Route path="/manage-inclusions" element={<ManageInclusions />} />

          <Route path="/manage-exclusions" element={<ManageExclusions />} />

          <Route path="/manage-essential" element={<ManageEssential />} />
          <Route path="/add-essential" element={<AddEssential />} />
          <Route path="/update-essential/:id" element={<AddEssential />} />

          <Route path="/questionnaire" element={<Questionnaire />} />

          <Route path="/hotel-experiance" element={<HotelExperiance />} />

          <Route path="/room" element={<HotelRooms />} />

          <Route path="/vehicle-info" element={<VehicleInfo />} />
          <Route path="/add-vehicle" element={<AddVehicle />} />
          <Route path="/update-vehicle/:id" element={<AddVehicle />} />

         
          <Route path="/add-partners" element={<AddPartners />} />
          
          <Route path="/cost-child" element={<CostForChild />} />
          <Route path="/flight-markup" element={<FlightMarkup />} />

          <Route path="/payment-milestone" element={<ManagePayment />} />
          <Route path="/add-milestone" element={<PaymentMilestones />} />
          <Route path="/update-milestone/:id" element={<PaymentMilestones />} />

          <Route
            path="/currency-conversion"
            element={<ManageCurrrencyConversion />}
          />
          <Route path="/add-conversion" element={<CurrencyConversions />} />
          <Route path="/update-conversion/:id" element={<CurrencyConversions />} />

          <Route path="/manage-flights" element={<ManageFlights />} />
          <Route path="/add-flight" element={<AddFlights />} />
          <Route path="/update-flight/:id" element={<AddFlights />} />

          <Route path="/curated" element={<Curated />} />
          <Route path="/package-page" element={< PackagePage/>} />
          <Route path="/general-page" element={<GeneralPage />} />
          <Route path="/contact" element={<Contact />} />


          <Route path="/home" element={<Home />} />
          
          <Route path="/about" element={<About />} />


          <Route path="/manage-enquiries" element={<ManageEnquiries />} />
          <Route path="/manage-promocodes" element={<PromoCodes />} />

          <Route path="/manage-bookings" element={<ManageBookings />} />
          <Route path="/view-booking" element={<ViewBooking />} />
          
          <Route path="/edit-enquiries" element={<EditEnquiries/>}/>

          <Route path="/manage-packages" element={<ManagePackages />} />
          <Route path="/add-package" element={<AddPackages />} />
          <Route path="/terms-condition" element={<TermsAndCondition/>} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          <Route path="/cancel-policy" element={<CancellationPolicy />} />
          <Route path="/booking-process" element={<BookingProcess />} />

        </Route>
      </Route>
    </Routes>
  );
}

export default RouteHandler;
