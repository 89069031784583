import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({
    name : 'commonReducer',
    initialState : {
        value: true,
        
    },
    reducers : {
        updateRedux: (state , data) => {
            state[data.payload.key ] = data.payload.value
        },
    },
})
export const { updateRedux } = commonSlice.actions;

export const value = (state ) => state.commonReducer.value;
export const logout_loader = (state ) => state.commonReducer.logout_loader;
export const login_loading = (state ) => state.commonReducer.login_loading;

export const season_data = (state ) => state.commonReducer.season_data;
export const season_page_details = (state ) => state.commonReducer.season_page_details;
export const season_path = (state ) => state.commonReducer.season_path;
export const detail_season_loading = (state ) => state.commonReducer.detail_season_loading;
export const season_details = (state ) => state.commonReducer.season_details;
export const update_season_loading = (state ) => state.commonReducer.update_season_loading;

export const attraction_data = (state ) => state.commonReducer.attraction_data;
export const attraction_page_details = (state ) => state.commonReducer.attraction_page_details;
export const attraction_path = (state ) => state.commonReducer.attraction_path;
export const update_attraction_loading = (state ) => state.commonReducer.update_attraction_loading;
export const detail_attraction_loading = (state ) => state.commonReducer.detail_attraction_loading;
export const attraction_details = (state ) => state.commonReducer.attraction_details;

export const update_activity_loading = (state ) => state.commonReducer.update_activity_loading;
export const activity_data = (state ) => state.commonReducer.activity_data;
export const activity_page_details = (state ) => state.commonReducer.activity_page_details;
export const activity_path = (state ) => state.commonReducer.activity_path;
export const detail_activity_loading = (state ) => state.commonReducer.detail_activity_loading;
export const activity_details = (state ) => state.commonReducer.activity_details;

export const inclusion_data = (state ) => state.commonReducer.inclusion_data;
export const inclusion_page_details = (state ) => state.commonReducer.inclusion_page_details;

export const exclusion_data = (state ) => state.commonReducer.exclusion_data;
export const exclusion_page_details = (state ) => state.commonReducer.exclusion_page_details;

export const update_faq_loading = (state ) => state.commonReducer.update_faq_loading;
export const faq_data = (state ) => state.commonReducer.faq_data;
export const faq_page_details = (state ) => state.commonReducer.faq_page_details;
export const detail_faq_loading = (state ) => state.commonReducer.detail_faq_loading;
export const faq_details = (state ) => state.commonReducer.faq_details;

export const questionnaire_data = (state ) => state.commonReducer.questionnaire_data;
export const questionnaire_page_details = (state ) => state.commonReducer.questionnaire_page_details;

export const detail_hotel_loading = (state ) => state.commonReducer.detail_hotel_loading;
export const hotel_details = (state ) => state.commonReducer.hotel_details;
export const hotel_path = (state ) => state.commonReducer.hotel_path;
export const hotel_partners_path = (state ) => state.commonReducer.hotel_partners_path;
export const hotel_gallery_path = (state ) => state.commonReducer.hotel_gallery_path;

export const update_room_loading = (state ) => state.commonReducer.update_room_loading;
export const detail_room_loading = (state ) => state.commonReducer.detail_room_loading;
export const room_details = (state ) => state.commonReducer.room_details;

export const update_vehicle_costing_loading = (state ) => state.commonReducer.update_vehicle_costing_loading;
export const detail_vehicle_costing_loading = (state ) => state.commonReducer.detail_vehicle_costing_loading;
export const vehicle_costing_details = (state ) => state.commonReducer.vehicle_costing_details;

export const update_child_cost_loading = (state ) => state.commonReducer.update_child_cost_loading;
export const detail_child_cost_loading = (state ) => state.commonReducer.detail_child_cost_loading;
export const child_cost_details = (state ) => state.commonReducer.child_cost_details;

export const update_flight_cost_loading = (state ) => state.commonReducer.update_flight_cost_loading;
export const detail_flight_cost_loading = (state ) => state.commonReducer.detail_flight_cost_loading;
export const flight_cost_details = (state ) => state.commonReducer.flight_cost_details;

export const update_payment_milestone_loading = (state ) => state.commonReducer.update_payment_milestone_loading;
export const payment_milestone_data = (state ) => state.commonReducer.payment_milestone_data;
export const payment_milestone_page_details = (state ) => state.commonReducer.payment_milestone_page_details;
export const detail_payment_milestone_loading = (state ) => state.commonReducer.detail_payment_milestone_loading;
export const payment_milestone_details = (state ) => state.commonReducer.payment_milestone_details;

export const update_currencyConversion_loading = (state ) => state.commonReducer.update_currencyConversion_loading;
export const currencyConversion_data = (state ) => state.commonReducer.currencyConversion_data;
export const currencyConversion_page_details = (state ) => state.commonReducer.currencyConversion_page_details;
export const detail_currencyConversion_loading = (state ) => state.commonReducer.detail_currencyConversion_loading;
export const currencyConversion_details = (state ) => state.commonReducer.currencyConversion_details;

export const update_flight_loading = (state ) => state.commonReducer.update_flight_loading;
export const flight_details = (state ) => state.commonReducer.flight_details;
export const flight_data = (state ) => state.commonReducer.flight_data;
export const flight_page_details = (state ) => state.commonReducer.flight_page_details;
export const flight_path = (state ) => state.commonReducer.flight_path;
export const detail_flight_loading = (state ) => state.commonReducer.detail_flight_loading;

export const fileUploadLoader = (state ) => state.commonReducer.fileUploadLoader;

export const update_vehicle_loading = (state ) => state.commonReducer.update_vehicle_loading;
export const vehicle_data = (state ) => state.commonReducer.vehicle_data;
export const vehicle_page_details = (state ) => state.commonReducer.vehicle_page_details;
export const detail_vehicle_loading = (state ) => state.commonReducer.detail_vehicle_loading;
export const vehicle_details = (state ) => state.commonReducer.vehicle_details;
export const vehicle_path = (state ) => state.commonReducer.vehicle_path;

export const update_vehicle_partners_loading = (state ) => state.commonReducer.update_vehicle_partners_loading;
export const detail_vehicle_partners_loading = (state ) => state.commonReducer.detail_vehicle_partners_loading;
export const vehicle_partners_details = (state ) => state.commonReducer.vehicle_partners_details;
export const vehicle_partners_path = (state ) => state.commonReducer.vehicle_partners_path;

export const update_curated_loading = (state ) => state.commonReducer.update_curated_loading;
export const detail_curated_loading = (state ) => state.commonReducer.detail_curated_loading;
export const curated_details = (state ) => state.commonReducer.curated_details;
export const curated_path = (state ) => state.commonReducer.curated_path;

export const update_about_loading = (state ) => state.commonReducer.update_about_loading;
export const detail_about_loading = (state ) => state.commonReducer.detail_about_loading;
export const about_details = (state ) => state.commonReducer.about_details;
export const about_path = (state ) => state.commonReducer.about_path;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;
// export const value = (state ) => state.commonReducer.value;




export default commonSlice.reducer;