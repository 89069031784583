import React from 'react'

import PolicyContent from '../components/PolicyContent'

const TermsAndCondition = () => {
    return (
        <PolicyContent title={"Terms And Condition"}/>

    )
}

export default TermsAndCondition