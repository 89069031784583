import React from 'react'
import Dropdown from 'react-bootstrap/Dropdown';

const EditEnquiries = () => {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <div className="table__add border-style mb-4">
                            <h5 className="card-title border-style mb-3">Enquiry Details</h5>
                            <Dropdown>
                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                    New Lead 1
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    <Dropdown.Item href="#/action-1">Discussion In Progress</Dropdown.Item>
                                    <Dropdown.Item href="#/action-2">Lead Converted</Dropdown.Item>
                                    <Dropdown.Item href="#/action-3">Lead Lost</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        <div className="row mb-4">
                            <div className="col-3">
                                <label >Submission Date</label>
                                <p className="mb-0">17 Oct 2024</p>
                            </div>
                            <div className="col-3">
                                <label >Customer Name</label>
                                <p className="mb-0">John Doe</p>
                            </div>
                            <div className="col-3">
                                <label >Email Address</label>
                                <p className="mb-0">john@gmail.com</p>
                            </div>
                            <div className="col-3">
                                <label>Phone Number</label>
                                <p className="mb-0">971551567899</p>
                            </div>
                        </div>

                        <div className="row mb-4">
                            <div className="col-3 enquiry_submission">
                                <label >Travel Dates</label>
                                <p className="mb-0">12 Nov 2024- 18 Nov 2024</p>
                            </div>
                            <div className="col-3">
                                <label>Number of Travellers</label>
                                <p className="mb-0">Adult : 2 , Child [0-2] : 2, Child [2-5]: 1</p>
                            </div>
                            <div className="col-3">
                                <label >Point Of Origin</label>
                                <p className="mb-0">Dubai</p>
                            </div>


                        </div>
                        <div className="row">

                            <div className="col-12 mb-5">
                                <label >Message</label>
                                <p className="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nobis dicta est nesciunt, aspernatur magni velit sit, eaque facere maxime dolorum ipsum eius earum quos soluta in cum quam dolore possimus.</p>
                            </div>
                        </div>

                        <div>
                            <div className="row mb-4">
                                <h5 className="card-title border-style mb-3">Updates</h5>


                                <div className="col-12 ">
                                    <div className="mb-3">
                                        <label className="form-label">Comment</label>
                                        <textarea className="form-control" id="comment" rows="3"></textarea>
                                    </div>
                                </div>
                                <div className="col-12 mb-3">
                                    <button type="submit" className="btn btn-primary ">Add Attachments </button>
                                </div>

                                <div className="col-12">
                                    <button type="submit" className="btn btn-primary me-3">Submit</button>
                                    <button type="submit" className="btn btn-danger">Cancel</button>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>


                <div className="card">
                    <div className="card-body">
                        <div className="row">
                            <div className="col-md-12">
                                <h4 className="card-title">History</h4>
                                <hr />
                            </div>
                            <div className="filter__elements mb-3">
                                <h4>-- Aug 2023 --</h4>

                            </div>


                            <div className="card history__card">
                                <div className="history-card">
                                    <div className="icon">
                                        <i className="far fa-calendar-check"></i>
                                    </div>
                                    <div className="card-element">
                                        <div className="card-header">
                                            Aug 4, 2023 12: 20 PM
                                        </div>
                                        <div className="card-body">
                                            <blockquote className="card-blockquote mb-0">
                                                <p className="mb-0"><b>Andrew</b> changed the status</p>

                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card history__card">
                                <div className="history-card">
                                    <div className="icon">
                                        <i className="far fa-calendar-check"></i>
                                    </div>
                                    <div className="card-element">
                                        <div className="card-header">
                                            Aug 4, 2023 12: 04 PM
                                        </div>
                                        <div className="card-body">
                                            <blockquote className="card-blockquote mb-0">
                                                <p className="mb-0"><b>Jo</b> assigned an enquiry</p>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default EditEnquiries