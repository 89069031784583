import axios from "axios";
import { toast } from "react-toastify";
import { API_BASE_URL } from "../constants/configuration";
import axiosInstance from "../utils/axiosInterceptor";
import { updateRedux } from "./commonReducer";

export function successToast(msg) {
  toast.success(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}
export function errorToast(msg) {
  toast.error(msg, {
    position: "bottom-center",
    autoClose: 3000,
  });
}

export const login = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "login_loading", value: true }));
  axios
    .post(`${API_BASE_URL}login`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "login_loading", value: false }));
      if (res.data.status === true) {
        localStorage.setItem("token", res.data.accessToken);
        localStorage.setItem('csrf', res.data.csrfToken)
        localStorage.setItem("role", res.data.role);
        localStorage.setItem("user", JSON.stringify(res.data));
        localStorage.setItem("user_id", res.data._id);
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error("Please check your username or password", {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "login_loading", value: false }));
      console.log(err);
    });
};

export const logout = (callback) => (dispatch) => {
  let data = {
    accessToken: localStorage.getItem("token"),
  };
  dispatch(updateRedux({ key: "logout_loader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}logout`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "logout_loader", value: false }));
      if (res.data.status) {
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "logout_loader", value: false }));
      console.log(err);
    });
};

export const insertSeason = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_season_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertSeason`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      console.log(err);
    });
};

export const getSeason = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getSeason`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "season_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "season_page_details", value: res.data.page }));
        dispatch(updateRedux({ key: "season_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteSeason = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_season_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteSeason/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      console.log(err);
    });
};

export const getSeasonDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_season_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getSeasonDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_season_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "season_details", value: res.data.data  }));
        dispatch(updateRedux({ key: "season_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_season_loading", value: false }));
      console.log(err);
    });
};

export const updateSeason = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_season_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateSeason`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_season_loading", value: false }));
      console.log(err);
    });
};

export const insertAttraction = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_attraction_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertAttraction`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      console.log(err);
    });
};

export const getAttraction = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getAttraction`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "attraction_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "attraction_page_details", value: res.data.page }));
        dispatch(updateRedux({ key: "attraction_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteAttraction = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_attraction_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteAttraction/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      console.log(err);
    });
};

export const getAttractionDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_attraction_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getAttractionDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_attraction_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "attraction_details", value: res.data.data  }));
        dispatch(updateRedux({ key: "attraction_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_attraction_loading", value: false }));
      console.log(err);
    });
};

export const updateAttraction = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_attraction_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateAttraction`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_attraction_loading", value: false }));
      console.log(err);
    });
};

export const insertActivity = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_activity_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertActivity`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      console.log(err);
    });
};

export const getActivity = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getActivity`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "activity_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "activity_page_details", value: res.data.page }));
        dispatch(updateRedux({ key: "activity_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteActivity = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_activity_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteActivity/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      console.log(err);
    });
};

export const getActivityDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_activity_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getActivityDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_activity_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "activity_details", value: res.data.data  }));
        dispatch(updateRedux({ key: "activity_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_activity_loading", value: false }));
      console.log(err);
    });
};

export const updateActivity = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_activity_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateActivity`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_activity_loading", value: false }));
      console.log(err);
    });
};

export const insertInclusion = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_inclusion_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertInclusion`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      console.log(err);
    });
};

export const getInclusion = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getInclusion`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "inclusion_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "inclusion_page_details", value: res.data.page }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteInclusion = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_inclusion_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteInclusion/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      console.log(err);
    });
};

export const getInclusionDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_inclusion_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getInclusionDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_inclusion_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "inclusion_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_inclusion_loading", value: false }));
      console.log(err);
    });
};

export const updateInclusion = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_inclusion_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateInclusion`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_inclusion_loading", value: false }));
      console.log(err);
    });
};

export const insertExclusion = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_exclusion_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertExclusion`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      console.log(err);
    });
};

export const getExclusion = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getExclusion`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "exclusion_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "exclusion_page_details", value: res.data.page }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteExclusion = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_exclusion_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteExclusion/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      console.log(err);
    });
};

export const getExclusionDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_exclusion_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getExclusionDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_exclusion_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "exclusion_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_exclusion_loading", value: false }));
      console.log(err);
    });
};

export const updateExclusion = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_exclusion_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateExclusion`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_exclusion_loading", value: false }));
      console.log(err);
    });
};

export const insertEssentialInfo = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertEssentialInfo`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const getEssentialInfo = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getEssentialInfo`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "faq_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "faq_page_details", value: res.data.page }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteEssentialInfo = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteEssentialInfo/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const getEssentialInfoDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_faq_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getEssentialInfoDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_faq_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "faq_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_faq_loading", value: false }));
      console.log(err);
    });
};

export const updateEssentialInfo = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_faq_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateEssentialInfo`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_faq_loading", value: false }));
      console.log(err);
    });
};

export const insertQuestionnaire = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_questionnaire_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertQuestionnaire`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_questionnaire_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_questionnaire_loading", value: false }));
      console.log(err);
    });
};

export const getQuestionnaire = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getQuestionnaire`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "questionnaire_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "questionnaire_page_details", value: res.data.page }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteQuestionnaire = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_questionnaire_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteQuestionnaire/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_questionnaire_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_questionnaire_loading", value: false }));
      console.log(err);
    });
};

export const getQuestionnaireDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_questionnaire_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getQuestionnaireDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_questionnaire_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "questionnaire_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_questionnaire_loading", value: false }));
      console.log(err);
    });
};

export const updateQuestionnaire = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_questionnaire_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateQuestionnaire`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_questionnaire_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_questionnaire_loading", value: false }));
      console.log(err);
    });
};

export const insertHotelExp = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_hotel_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertHotelExp`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_hotel_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_hotel_loading", value: false }));
      console.log(err);
    });
};

export const getHotelExpDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_hotel_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getHotelExpDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_hotel_loading", value: false }));
      if (res.data.status) {	
        dispatch(updateRedux({ key: "hotel_details", value: res.data.data  }));	
        dispatch(updateRedux({ key: "hotel_path", value: res.data.path }));
        dispatch(updateRedux({ key: "hotel_partners_path", value: res.data.partnersPath }));
        dispatch(updateRedux({ key: "hotel_gallery_path", value: res.data.galleryPath }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_hotel_loading", value: false }));
      console.log(err);
    });
};

export const updateHotelExp = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_hotel_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateHotelExp`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_hotel_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_hotel_loading", value: false }));
      console.log(err);
    });
};

export const insertRoomCosting = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_room_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertRoomCosting`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_room_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_room_loading", value: false }));
      console.log(err);
    });
};

export const getRoomCostDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_room_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getRoomCostDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_room_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "room_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_room_loading", value: false }));
      console.log(err);
    });
};

export const insertVehicleCosting = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_vehicle_costing_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertVehicleCosting`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_vehicle_costing_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_vehicle_costing_loading", value: false }));
      console.log(err);
    });
};

export const getVehicleCostDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_vehicle_costing_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getVehicleCostDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_vehicle_costing_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "vehicle_costing_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_vehicle_costing_loading", value: false }));
      console.log(err);
    });
};

export const insertChildCost = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_child_cost_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertChildCost`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_child_cost_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_child_cost_loading", value: false }));
      console.log(err);
    });
};

export const getChildCostDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_child_cost_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getChildCostDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_child_cost_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "child_cost_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_child_cost_loading", value: false }));
      console.log(err);
    });
};

export const insertFlightCost = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_flight_cost_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertFlightCost`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_flight_cost_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_flight_cost_loading", value: false }));
      console.log(err);
    });
};

export const getFlightCostDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_flight_cost_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getFlightCostDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_flight_cost_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "flight_cost_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_flight_cost_loading", value: false }));
      console.log(err);
    });
};

export const insertMilestone = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_payment_milestone_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertMilestone`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_payment_milestone_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_payment_milestone_loading", value: false }));
      console.log(err);
    });
};

export const getMilestone = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getMilestone`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "payment_milestone_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "payment_milestone_page_details", value: res.data.page }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteMilestone = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_payment_milestone_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteMilestone/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_payment_milestone_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_payment_milestone_loading", value: false }));
      console.log(err);
    });
};

export const getMilestoneDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_payment_milestone_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getMilestoneDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_payment_milestone_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "payment_milestone_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_payment_milestone_loading", value: false }));
      console.log(err);
    });
};

export const updateMilestone = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_payment_milestone_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateMilestone`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_payment_milestone_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_payment_milestone_loading", value: false }));
      console.log(err);
    });
};

export const insertCurrency = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_currencyConversion_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertCurrency`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_currencyConversion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_currencyConversion_loading", value: false }));
      console.log(err);
    });
};

export const getCurrency = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getCurrency`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "currencyConversion_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "currencyConversion_page_details", value: res.data.page }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteCurrency = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_currencyConversion_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteCurrency/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_currencyConversion_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_currencyConversion_loading", value: false }));
      console.log(err);
    });
};

export const getCurrencyDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_currencyConversion_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getCurrencyDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_currencyConversion_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "currencyConversion_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_currencyConversion_loading", value: false }));
      console.log(err);
    });
};

export const updateCurrency = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_currencyConversion_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateCurrency`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_currencyConversion_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_currencyConversion_loading", value: false }));
      console.log(err);
    });
};

export const insertFlight = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_flight_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertFlight`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      console.log(err);
    });
};

export const getFlight = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getFlight`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "flight_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "flight_page_details", value: res.data.page }));
        dispatch(updateRedux({ key: "flight_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteFlight = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_flight_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteFlight/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      console.log(err);
    });
};

export const getFlightDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_flight_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getFlightDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_flight_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "flight_details", value: res.data.data  }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_flight_loading", value: false }));
      console.log(err);
    });
};

export const updateFlight = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_flight_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateFlight`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_flight_loading", value: false }));
      console.log(err);
    });
};

export const uploadFile = (formData, callback, progress) => (dispatch) => {
  dispatch(updateRedux({ key: "fileUploadLoader", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}uploadFile`, formData, {
      onUploadProgress: (e) =>
        progress && progress(Math.round(e.progress * 100)),
    })
    .then((res) => {
      dispatch(updateRedux({ key: "fileUploadLoader", value: false }));
      if (res.data.status == true) {
        if (callback) {
          callback(res.data);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const insertVehicleInfo = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_vehicle_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertVehicleInfo`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      console.log(err);
    });
};

export const getVehicleInfo = (data) => (dispatch) => {
  axiosInstance
    .post(`${API_BASE_URL}getVehicleInfo`)
    .then((res) => {
      if (res.data.status) {
        dispatch(updateRedux({ key: "vehicle_data", value: res.data.data	 }));	
        dispatch(updateRedux({ key: "vehicle_page_details", value: res.data.page }));
        dispatch(updateRedux({ key: "vehicle_path", value: res.data.path }));
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      console.log(err);
    });
};

export const deleteVehicleInfo = (id, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_vehicle_loading", value: true }));
  axiosInstance
    .delete(`${API_BASE_URL}deleteVehicleInfo/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      if (res.data.status) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback();
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      console.log(err);
    });
};

export const getVehicleInfoDetail = (id) => (dispatch) => {
  dispatch(updateRedux({ key: "detail_vehicle_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getVehicleInfoDetail/${id}`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_vehicle_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "vehicle_details", value: res.data.data  }));
        dispatch(updateRedux({ key: "vehicle_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_vehicle_loading", value: false }));
      console.log(err);
    });
};

export const updateVehicleInfo = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_vehicle_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateVehicleInfo`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_vehicle_loading", value: false }));
      console.log(err);
    });
};

export const insertVehiclePartners = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_vehicle_partners_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertVehiclePartners`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_vehicle_partners_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_vehicle_partners_loading", value: false }));
      console.log(err);
    });
};

export const getPartnersDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_vehicle_partners_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getPartnersDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_vehicle_partners_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "vehicle_partners_details", value: res.data.data  }));
        dispatch(updateRedux({ key: "vehicle_partners_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_vehicle_partners_loading", value: false }));
      console.log(err);
    });
};

export const insertHotelPartners = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_hotel_partners_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertHotelPartners`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_hotel_partners_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_hotel_partners_loading", value: false }));
      console.log(err);
    });
};

export const insertHotelGallery = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_hotel_gallery_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertHotelGallery`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_hotel_gallery_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_hotel_gallery_loading", value: false }));
      console.log(err);
    });
};

export const insertCurated = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_curated_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertCurated`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_curated_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_curated_loading", value: false }));
      console.log(err);
    });
};

export const getCuratedDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_curated_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getCuratedDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_curated_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "curated_details", value: res.data.data  }));
        dispatch(updateRedux({ key: "curated_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_curated_loading", value: false }));
      console.log(err);
    });
};

export const updateCurated = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_curated_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateCurated`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_curated_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_curated_loading", value: false }));
      console.log(err);
    });
};

export const insertAbout = (data, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_about_loading", value: true }));
  axiosInstance
    .post(`${API_BASE_URL}insertAbout`, data)
    .then((res) => {
      dispatch(updateRedux({ key: "update_about_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_about_loading", value: false }));
      console.log(err);
    });
};

export const getAboutDetail = () => (dispatch) => {
  dispatch(updateRedux({ key: "detail_about_loading", value: true }));
  axiosInstance
    .get(`${API_BASE_URL}getAboutDetail`)
    .then((res) => {
      dispatch(updateRedux({ key: "detail_about_loading", value: false }));
      if (res.data.status) {
        dispatch(updateRedux({ key: "about_details", value: res.data.data  }));
        dispatch(updateRedux({ key: "about_path", value: res.data.path }));
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "detail_about_loading", value: false }));
      console.log(err);
    });
};

export const updateAbout = (formData, callback) => (dispatch) => {
  dispatch(updateRedux({ key: "update_about_loading", value: true }));
  axiosInstance
    .put(`${API_BASE_URL}updateAbout`, formData)
    .then((res) => {
      dispatch(updateRedux({ key: "update_about_loading", value: false }));
      if (res.data.status == true) {
        toast.success(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
        if (callback) {
          callback(res.data.status);
        }
      } else {
        toast.error(res.data.message, {
          position: "bottom-center",
          autoClose: 3000,
        });
      }
    })
    .catch((err) => {
      dispatch(updateRedux({ key: "update_about_loading", value: false }));
      console.log(err);
    });
};