import React from "react";
import { Modal } from "react-bootstrap";

export default function DeleteModal({
  showDeleteModal,
  handleClose,
  deleteHandler,
  title,
}) {
  return (
    <Modal size="lg" show={showDeleteModal} centered onHide={handleClose}>
      <Modal.Body>
        <div className="container_deletemodal">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon_deletemodal"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth="2"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
          <p className="title_deletemodal">{title}</p>

          <p className="description_deletemodal">
            Are you sure you want to delete{" "}
          </p>
          <div className="actions_deletemodal">
            <button className="delete-btn" onClick={deleteHandler}>
              Yes
            </button>
            <button className="cancel-btn" onClick={handleClose}>
              No
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}
