import React from 'react';
import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';

const IconUploader = ({ media, onRemove, onUpload, index }) => {
  return (
    <div className="iconupload_container">
      {media?.preview ? (
        <div className="iconupload_preview">
          <div className="icon-showcontainer">
            <img src={media.preview} alt="Uploaded Preview" />
          </div>
          <AiOutlineClose size={30} onClick={onRemove} className='remove-icon' />

        </div>
      ) : (
        <label className="upload-label">
          <AiOutlinePlus size={30} />
          <input
            type="file"
            accept="image/*,video/*"
            onChange={onUpload}
            className="file-input"
          />
        </label>
      )}

      {index !== undefined && (
        <div className='index_number'>
          <p>{index + 1}</p>
        </div>
      )}
    </div>
  );
};

export default IconUploader;
