import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import { Link } from 'react-router-dom';
import { FaSlidersH } from "react-icons/fa";

import { useTable, useSortBy } from 'react-table';
import ReactPaginate from 'react-paginate';

const data = [
    { slno: '1', id: '111', date: "12 May 2025", customerName: "Jhone Due", phone: '93489734732', email: "ldskjf@gmail.com", ChosenPackage: "Curated", TravelDuration: "tested" },

];

const columns = [
    { Header: 'Sl no', accessor: 'slno' },
    // { Header: 'Booking Number', accessor: 'bookingNumber' },
    { Header: 'Booking Reference ID', accessor: 'id' },
    { Header: 'Date', accessor: 'date' },
    { Header: 'Customer Name', accessor: 'customerName' },
    { Header: 'Phone', accessor: 'phone' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'Chosen Package', accessor: 'ChosenPackage' },
    { Header: 'Duration of Travel', accessor: 'TravelDuration' },
    {
        Header: 'Status',

        Cell: () => (
            <Dropdown>
                <Dropdown.Toggle variant="success" id="dropdown-basic">
                    New Lead 1
                </Dropdown.Toggle>

                <Dropdown.Menu>
                    <Dropdown.Item href="#/action-1">Discussion In Progress</Dropdown.Item>
                    <Dropdown.Item href="#/action-2">Lead Converted</Dropdown.Item>
                    <Dropdown.Item href="#/action-3">Lead Lost</Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>

        )
    },
    { Header: 'Action', Cell: () => <button className="btn-sm btn-secondary"><Link to="/view-booking"><i data-feather="edit"></i></Link></button> }
];
const ManageBookings = () => {
    const [open, setOpen] = useState(false)
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow
    } = useTable(
        {
            columns,
            data
        },
        useSortBy
    );



    const renderSortIcons = (column) => {
        if (column.isSorted) {
            return column.isSortedDesc ? (
                <span className="sort-icon">&#9660;</span>
            ) : (
                <span className="sort-icon">&#9650;</span>
            );
        } else {
            return (
                <>
                    <span className="sort-icon">&#9650;</span>
                    <span className="sort-icon">&#9660;</span>
                </>
            );
        }
    };


    return (
        <div className="row">
            <div className="col pe-0">
                <div className="card ">
                    <div className="card-body">
                        <div className="table__add border-style ">
                            <h5 className="card-title ">Manage Bookings</h5>

                            <button className='me-2 filter_btn'><FaSlidersH onClick={() => setOpen(!open)} size={20} /></button>
                        </div>

                        <div className="search__head">
                            <div className="count">
                                <p>Show</p>
                                <input type="number" className='form-control' value={10} readOnly />
                                <p>entries</p>
                            </div>
                            <div className="search">
                                <span className='me-2'>Search: </span> <input type="text" className='form-control' placeholder='Search here' />
                                <i data-feather="search"></i>
                            </div>
                        </div>



                        {open && (
                            <div className="col pe-0">
                                <div className="row mb-4 row-cols-5">
                                    <div className="col pe-0">
                                        <label className="form-label">Booking Reference ID</label>
                                        <input type="text" className='form-control' placeholder='Search Here' />
                                    </div>
                                    <div className="col pe-0">
                                        <label className="form-label">Customer Name</label>
                                        <input type="text" className='form-control' placeholder='Search Here' />
                                    </div>
                                    <div className="col pe-0">
                                        <label className="form-label">Email</label>
                                        <input type="text" className='form-control' placeholder='Search Here' />
                                    </div>
                                    <div className="col pe-0">
                                        <label className="form-label">Status</label>
                                        <select class="form-select" aria-label="Default select example">
                                            <option selected>Select</option>
                                            <option value="1">One</option>
                                            <option value="2">Two</option>
                                            <option value="3">Three</option>
                                        </select>

                                    </div>

                                    <div className="col col-md- preview-imagemargin">
                                        <button className='btn btn-primary '>Search</button>
                                    </div>

                                </div>

                            </div>
                        )}
                        <div >
                            <table {...getTableProps()} className="react-table table-bordered table-striped table-hover">
                                <thead className="thead-light">
                                    {headerGroups.map(headerGroup => (
                                        <tr {...headerGroup.getHeaderGroupProps()} key={headerGroup.id}>
                                            {headerGroup.headers.map(column => (
                                                <th
                                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                                    key={column.id}
                                                    className="text-center"
                                                >
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        {column.render('Header')}
                                                        {(column.id !== 'action' && column.id !== 'role') && (
                                                            <span className="ml-2 arrow_alignment">
                                                                {renderSortIcons(column)}
                                                            </span>
                                                        )}
                                                    </div>

                                                </th>
                                            ))}
                                        </tr>
                                    ))}
                                </thead>
                                <tbody {...getTableBodyProps()}>
                                    {rows.map(row => {
                                        prepareRow(row);
                                        return (
                                            <tr {...row.getRowProps()} key={row.id}>
                                                {row.cells.map(cell => (
                                                    <td {...cell.getCellProps()} key={cell.column.id}>
                                                        {cell.render('Cell')}
                                                    </td>
                                                ))}
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </div>

                        <div className="default__pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<i data-feather="chevron-right"></i>}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={2}
                                previousLabel={<i data-feather="chevron-left"></i>}
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default ManageBookings