import { AiOutlineClose, AiOutlinePlus } from 'react-icons/ai';

const MediaUploader = ({ media, onUpload, onRemove }) => {
    const isVideo = media?.file?.type?.includes('video');
    
    return (
        <div className="uploader-container">
            {media?.preview ? (
                <div className="uploader-preview">
                    {isVideo ? (
                        <div className='image-showcontainer'>
                        <video controls >
                            <source src={media.preview} type={media.type} />
                            Your browser does not support the video tag.
                        </video>
                        </div>
                    ) : (
                        <div className="image-showcontainer">
                          
                        <img src={media.preview} alt="Uploaded Preview"  />
                        </div>
                    )}
                    <AiOutlineClose size={30} className="remove-icon" onClick={onRemove} />
                </div>
            ) : (
                <label className="upload-label">
                    <AiOutlinePlus size={30} className="plus-icon" />
                    <input
                        type="file"
                        accept="image/*,video/*"
                        onChange={onUpload}
                        className="file-input"
                    />
                </label>
            )}
        </div>
    );
};


export default MediaUploader;
