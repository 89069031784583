import React, { useEffect, useState } from "react";
import SortableItem from "./SortableItem";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import "./sortStyle.scss";

const SortableList = ({
  setInputs,
  inputs,
  handleDelete,
  randomTrackID,
  field,
  err,
  altChangeHandler
}) => {
  const [items, setItems] = useState([]);
  useEffect(() => {
    if (inputs?.[field]) {
      setItems(inputs?.[field]);
    }
  }, [inputs, field]);

  const moveItem = (fromIndex, toIndex) => {
    const updatedItems = [...items];
    const [movedItem] = updatedItems.splice(fromIndex, 1);
    updatedItems.splice(toIndex, 0, movedItem);
    setItems(updatedItems);
    setInputs([]);
    setInputs({
      ...inputs,
      [field]: updatedItems,
    });
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="grid-container">
        {items.map((file, index) => (
          <SortableItem
            key={file.id}
            index={index}
            file={file}
            moveItem={moveItem}
            handleDelete={handleDelete}
            field={field}
            randomTrackID={randomTrackID}
            err={err}
            altChangeHandler= {altChangeHandler}
          />
        ))}
      </div>
    </DndProvider>
  );
};

export default SortableList;
