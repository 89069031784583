import ReactQuill from "react-quill";
import { toolbarOptions } from "../../constants/config";
import FileUpload from "../../commonComponents/FileUpload/FileUpload";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../constants/configuration";
import { useEffect, useState } from "react";
import {
  activity_details,
  activity_path,
  detail_activity_loading,
  update_activity_loading,
} from "../../redux/commonReducer";
import {
  getActivityDetail,
  insertActivity,
  updateActivity,
} from "../../redux/actionCreator";
import { Form, Spinner } from "react-bootstrap";
import { duration } from "moment/moment";
import MediaUploader from "../../components/media-uploader/MediaUploader";

export default function Activities({ setOpen }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();

  const loading = useSelector(detail_activity_loading);
  const submitLoading = useSelector(update_activity_loading);
  const details = useSelector(activity_details);
  const path = useSelector(activity_path);

  const [data, setData] = useState({
    title: "",
    image: "",
    duration: "",
    long_description: "",
    is_billable: "",
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    if (id) {
      dispatch(getActivityDetail(id));
    }
  }, [id]);

  useEffect(() => {
    if (details && id) {
      let result = {
        title: details?.title,
        duration: details?.duration,
        long_description: details?.long_description,
        is_billable: details?.is_billable,
        // image: details?.image
        //   ? [
        //       {
        //         type: details?.image?.split(".").pop(),
        //         url: `${IMAGE_BASE_URL}${path}/${details?.image}`,
        //         name: `${details?.image}`,
        //         response: `${details?.image}`,
        //       },
        //     ]
        //   : "",
        image: details?.image
          ? {
              preview: `${IMAGE_BASE_URL}${path}/${details?.image}`,
              file: null,
              image: details?.image,
            }
          : "",
        image_alt: details?.image_alt,
      };
      setData({ ...result });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const documentUploadHandler = (field, e, isInvalid) => {
    if (isInvalid) {
      setErr({
        ...err,
        [field]: "Invalid file format",
      });
    } else {
      setErr({
        ...err,
        [field]: "",
      });
      setData({
        ...data,
        [field]: [
          {
            type: e[0].type,
            url: e[0].url,
            name: e[0]?.name,
            metaFile: e,
            response: e,
          },
        ],
      });
      setErr({
        ...err,
        [field]: "",
      });
    }
  };

  const validate = () => {
    let temp = {};
    if (!data?.title?.trim()?.length) {
      temp = {
        ...temp,
        title: "Please enter title",
      };
    }
    if (!data?.image_alt?.trim()?.length) {
      temp = {
        ...temp,
        image_alt: "Please enter alt text",
      };
    }
    if (!data?.duration?.trim()?.length) {
      temp = {
        ...temp,
        duration: "Please enter duration",
      };
    }
    if (data?.long_description == "") {
      temp = {
        ...temp,
        long_description: "Please select long description",
      };
    }
    if (data?.image == "") {
      temp = {
        ...temp,
        image: "Please upload image",
      };
    }
    if (data?.is_billable == "") {
      temp = {
        ...temp,
        is_billable: "Please choose billable value",
      };
    }
    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };

  const onSubmitHandler = () => {
    if (validate()) {
      let formData = new FormData();
      formData.append("title", data?.title?.trim());
      formData.append("long_description", data?.long_description);
      // formData.append(
      //   "image",
      //   data?.image?.[0]?.metaFile?.[0]?.metaFile ?? data?.image?.[0]?.name
      // );
      formData.append("image", data?.image?.file ?? data?.image?.image);
      formData.append("image_alt", data?.image_alt?.trim());
      formData.append("duration", data?.duration?.trim());
      formData.append("is_billable", data?.is_billable);
      console.log(formData);
      if (id) {
        formData.append("id", id);
        dispatch(
          updateActivity(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      } else {
        dispatch(
          insertActivity(formData, (res) => {
            if (res) {
              onCancelHandler();
            }
          })
        );
      }
    }
  };

  const onCancelHandler = () => {
    setData({
      title: "",
      image: "",
      duration: "",
      long_description: "",
      is_billable: "",
    });
    navigate("/manage-activity");
  };

  const handleImageUpload = (event, type) => {
    const file = event.target.files?.[0];
    if (file) {
      setData((prevState) => ({
        ...prevState,
        [type]: { file, preview: URL.createObjectURL(file) },
      }));
    }
  };

  const removeImage = (type) => {
    setData((prevState) => ({
      ...prevState,
      [type]: "",
    }));
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Add Activity</h5>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Title</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="type here"
                    onChange={(e) => onChangeHandler("title", e?.target?.value)}
                    value={data?.title}
                  />
                  <small id="error" className="error">
                    {err?.title}
                  </small>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  {/* <label className="form-label">Image</label>
                  <input
                    type="file"
                    className="form-control"
                    placeholder="type here"
                  /> */}
                  <label className="form-label">Upload Image</label>
                  <MediaUploader
                    media={data.image}
                    onUpload={(e) => handleImageUpload(e, "image")}
                    onRemove={() => removeImage("image")}
                  />
                  <small id="error" className="error">
                    {err?.image}
                  </small>
                  {/* <FileUpload
                    className="css-atc-esc2"
                    label={"Upload Image"}
                    buttonLabel="Choose file"
                    accept={["jpg", "jpeg", "png", "svg"]}
                    onChange={(e, isInvalid) =>
                      documentUploadHandler("image", e, isInvalid)
                    }
                    error={err.image}
                    id="image"
                    files={data?.image}
                    // required
                    inputSocket={() => {}}
                  /> */}
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Add Alt Text</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Type here"
                    onChange={(e) =>
                      onChangeHandler("image_alt", e?.target?.value)
                    }
                    value={data?.image_alt}
                  />
                  <small id="error" className="error">
                    {err?.image_alt}
                  </small>
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Duration</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="type here"
                    onChange={(e) =>
                      onChangeHandler("duration", e?.target?.value)
                    }
                    value={data?.duration}
                  />
                  <small id="error" className="error">
                    {err?.duration}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Long Description</label>
                  <ReactQuill
                    theme="snow"
                    modules={{
                      toolbar: toolbarOptions,
                    }}
                    placeholder="type here"
                    onChange={(e) => onChangeHandler("long_description", e)}
                    value={data?.long_description}
                  />
                  <small id="error" className="error">
                    {err?.long_description}
                  </small>
                </div>
              </div>
              <div className="col-6">
                <label className="form-label">Billable Activity</label>
                <fieldset className="row mb-3">
                  <div
                    className="col-sm-10 d-flex gap-10"
                    style={{ gap: "20px" }}
                  >
                    <Form.Check
                      type={"radio"}
                      id={`default-radio`}
                      label={"Yes"}
                      onClick={(e) =>
                        onChangeHandler("is_billable", e.target.value)
                      }
                      value={"1"}
                      checked={data?.is_billable == "1"}
                    />
                    <Form.Check
                      type={"radio"}
                      id={`default-radio`}
                      label={"No"}
                      onClick={(e) =>
                        onChangeHandler("is_billable", e.target.value)
                      }
                      value={"0"}
                      checked={data?.is_billable == "0"}
                    />
                  </div>
                  <small id="error" className="error">
                    {err?.is_billable}
                  </small>
                </fieldset>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary m-2"
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
