import React, { useState } from 'react'
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../constants/config'
import DatePicker from 'react-datepicker'
import Select from 'react-select'
import StatusCard from '../../components/StatusCard'
import Seo from '../../components/Seo'

export default function Blog() {


  const [startDate, setStartDate] = useState(new Date());
  const [selectedOption, setSelectedOption] = useState([]);

  const handleChange = (selected) => {
    setSelectedOption(selected || []);
  };
  const options = [
    { value: 'Jan', label: 'Jan' },
    { value: 'Feb', label: 'Feb' },
    { value: 'Mar', label: 'Mar' },
    { value: 'Apr', label: 'Apr' },
    { value: 'May', label: 'May' },
    { value: 'Jun', label: 'Jun' },
  ];
  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Add Blog</h5>
            <div className="row">
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Title</label>
                  <input type="text" className="form-control" placeholder="Type here" />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Thumbnail</label>
                  <input type="file" className="form-control" placeholder="Type here" />
                </div>
              </div>
              <div className="col-6">
                <div className="mb-3">
                  <label className="form-label">Publish Date</label>
                  <DatePicker className="form-control" selected={startDate} onChange={(date) => setStartDate(date)} />
                </div>
              </div>

              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Short Description</label>
                  <ReactQuill theme="snow" modules={{
                    toolbar: toolbarOptions
                  }}
                    placeholder='Type here'
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="mb-3">
                  <label className="form-label">Long Description</label>
                  <ReactQuill theme="snow" modules={{
                    toolbar: toolbarOptions
                  }}
                    placeholder='Type here'
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-6 mb-3">
                  <label className="form-label">Blog Images</label>
                  <input
                    type="file"
                    className="form-control"
                    multiple
                    accept="image/*"

                  />
                </div>
                <div className="col-12 col-md-4 mb-3">
                  <label className="form-label">Blog Videos</label>
                  <input
                    type="text"
                    className="form-control"
                    placeholder='Video url'


                  />
                </div>
                <div className="col-12 col-md-2 mb-3 margin-container">
                  <button type="submit" className="btn btn-primary m-2">Add</button>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 mb-3">

                  <label className="form-label">Associated Packages</label>
                  <Select
                    isMulti
                    value={selectedOption}
                    onChange={handleChange}
                    options={options}
                    placeholder="Select Activities"
                  />
                </div>
                

              </div>
              <div className="col-12">
                <button type="submit" className="btn btn-primary m-2">Submit</button>
                <button type="submit" className="btn btn-danger">Cancel</button>
              </div>
            </div>
          </div>
        </div>

         <StatusCard/>
         <div className="card">
          <div className="card-body">
         <Seo/>
          </div>
         </div>
    
      </div>


    </div>


  )
}
