import React from 'react'

const History = () => {
  return (
    <div className="card">
    <div className="card-body">
        <div className="row">

            <h4 className="card-title border-style mb-3">History</h4>


            {/* <div className="filter__elements mb-3">
                <h4>-- Aug 2023 --</h4>

            </div> */}


            <div className="card history__card">
                <div className="history-card">
                    <div className="icon">
                        <i className="far fa-calendar-check"></i>
                    </div>
                    <div className="card-element">
                        <div className="history-header ps-4">
                            Aug 4, 2023 12: 20 PM
                        </div>
                        <div className="card-body ps-4">
                            <blockquote className="card-blockquote mb-0">
                                <p className="mb-0"><b>Andrew</b> changed the status</p>

                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
            <div className="card history__card">
                <div className="history-card">
                    <div className="icon">
                        <i className="far fa-calendar-check"></i>
                    </div>
                    <div className="card-element">
                        <div className="history-header ps-4">
                            Aug 4, 2023 12: 04 PM
                        </div>
                        <div className="card-body ps-4">
                            <blockquote className="card-blockquote mb-0">
                                <p className="mb-0"><b>Jo</b> assigned an enquiry</p>
                            </blockquote>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    </div>

</div>
  )
}

export default History