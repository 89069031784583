import React from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'

export default function ManageBlogs() {
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <div className="table__add border-style">
                            <h5 className="card-title">Manage Blogs</h5>
                            <Link to='/add-blog'><button className='btn btn-primary'>Add Blog</button></Link>
                        </div>
                        <div className="search__head">
                            <div className="count">
                                <p>Show</p>
                                <input type="number" className='form-control' value={10} />
                                <p>entries</p>
                            </div>
                            <div className="search">
                                <input type="text" className='form-control' placeholder='Search here' />
                                <i data-feather="search"></i>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table full-title">
                                <thead>
                                    <tr>
                                       
                                        <th>Thumbnail</th>
                                        <th>Date</th>
                                        <th>Title</th>
                                        <th scope="col">Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                    <td><img src="https://picsum.photos/seed/picsum/200/300" alt="" /></td>
                                        <td className='nowrap'>17 May 2024</td>
                                        <td>Lorem ipsum dolor sit amet consectetur adipisicing elit. Repudiandae impedit, consequatur blanditiis deserunt suscipit distinctio! </td>
                                        <td className='actions'>
                                            <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                            <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div className="default__pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<i data-feather="chevron-right"></i>}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={2}
                                previousLabel={<i data-feather="chevron-left"></i>}
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
