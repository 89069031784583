import React, { useState } from 'react'
import Dropdown from 'react-bootstrap/Dropdown';
import History from '../../components/History';
import CommentUpdates from '../../components/CommentUpdates';
import Accordion from 'react-bootstrap/Accordion';
import * as Tabs from '@radix-ui/react-tabs';
import ReactQuill from 'react-quill'
import { toolbarOptions } from '../../constants/config';
import Select from 'react-select'
import { Form } from 'react-bootstrap';

const ViewBooking = () => {
    const [activeTab, setActiveTab] = useState(6);
    const [milestone, setMilestone] = useState(1);
    const [activeHistoryTab, setActiveHistoryTab] = useState(0);
    const [selectedOption, setSelectedOption] = useState([]);

    const tabs = ['Booking Details', 'Passenger Info', 'Customer Engagement', 'Travel Itinerary', 'Quotation', 'Payments', 'Review'];
    const historyTabs = ['Comments Updates', 'History'];
    const options = [
        { value: 'Jan', label: 'Jan' },
        { value: 'Feb', label: 'Feb' },
        { value: 'Mar', label: 'Mar' },
        { value: 'Apr', label: 'Apr' },
        { value: 'May', label: 'May' },
        { value: 'Jun', label: 'Jun' },
    ];
    const handleChange = (selected) => {
        setSelectedOption(selected || []);
    };

    const handleDelete = (optionToDelete) => {
        setSelectedOption(prev => prev.filter(option => option.value !== optionToDelete.value));
    };

    const milestoneSelect = (e) => {
        setMilestone(e.target.value)
    }
    return (
        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <div className="table__add border-style">
                            <h5 className="card-title">View Booking Details</h5>
                            <div className='booking'>
                                <div className="number">
                                    <h2>BOOKING NUMBER</h2>
                                    <h5>SKYWARD1232</h5>
                                </div>
                                <Dropdown>
                                    <Dropdown.Toggle variant="success" id="dropdown-basic">
                                        New Lead 1
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item href="#/action-1">Discussion In Progress</Dropdown.Item>
                                        <Dropdown.Item href="#/action-2">Lead Converted</Dropdown.Item>
                                        <Dropdown.Item href="#/action-3">Lead Lost</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>

                        </div>
                        <div className="default__tabs">
                            <ul>
                                {tabs.map((tab, index) => (
                                    <li
                                        key={index}
                                        className={activeTab === index ? 'active' : ''}
                                        onClick={() => setActiveTab(index)}
                                    >
                                        {tab}
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>



                {
                    activeTab === 0 &&
                    <div className="card">
                        <div className="card-body pt-0">
                            <div className="tab-content">
                                <div className="tab__header">
                                    <h2>Booking Details</h2>
                                </div>
                                <div className="grid__items">
                                    <div className="item grid-2">
                                        <h4>Package Name</h4>
                                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Saepe tempora facere animi accusamus numquam officiis</p>
                                    </div>
                                    <div className="item">
                                        <h4>Duration</h4>
                                        <p>8 Days</p>
                                    </div>
                                    <div className="item">
                                        <h4>Booking Source</h4>
                                        <p>Website</p>
                                    </div>
                                    <div className="item">
                                        <h4>Booking Date</h4>
                                        <p>26 may 2024</p>
                                    </div>
                                    <div className="item">
                                        <h4>Token Payment </h4>
                                        <p>$123</p>
                                    </div>
                                </div>
                                <div className="tab__header">
                                    <h2>Travel Details</h2>
                                </div>
                                <div className="grid__items">
                                    <div className="item">
                                        <h4>Travel Dates</h4>
                                        <p>20 may 2024</p>
                                    </div>
                                    <div className="item">
                                        <h4>Total no. of travelers</h4>
                                        <p>7</p>
                                    </div>
                                    <div className="item">
                                        <h4>Point of origin</h4>
                                        <p>lorem ipsum dummy</p>
                                    </div>
                                    <div className="item">
                                        <h4>Traveler Count</h4>
                                        <p>Adult <div className="badge bg-primary mb-2" style={{ fontSize: '12px', fontWeight: "600" }}>4</div></p>
                                        <p>Child (0-2) <div className="badge bg-primary mb-2" style={{ fontSize: '12px', fontWeight: "600" }}>2</div></p>
                                    </div>


                                </div>
                                <div className="tab__header">
                                    <h2>Flight Selection</h2>
                                </div>

                                <div className="card__header">
                                    <h5>Start: Tue, 8 May</h5>
                                </div>
                                <div className="trip_details">
                                    <div className="grid">
                                        <div class="flight-info">
                                            <div class="departure">
                                                <h4 class="time">23:45</h4>
                                                <h5 class="city">Dubai</h5>
                                            </div>
                                            <div class="flight-details">
                                                <span class="duration">21 h 35 m</span>
                                                <span class="plane-icon"><img src="/assets/img/icons/aeroplane.svg" alt="" /></span>
                                                <span class="dashed-line"></span>
                                                <span class="stops">1 stop, CPH</span>
                                            </div>
                                            <div class="arrival">
                                                <h4 class="time">17:20</h4>
                                                <h5 class="city">Keflavik</h5>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="grid">
                                        <div class="flight-info">
                                            <div class="departure">
                                                <h4 class="time">10:40</h4>
                                                <h5 class="city">Keflavik</h5>
                                            </div>
                                            <div class="flight-details">
                                                <span class="duration">21 h 35 m</span>
                                                <span class="plane-icon return"><img src="/assets/img/icons/aeroplane.svg" alt="" /></span>
                                                <span class="dashed-line"></span>
                                                <span class="stops">1 stop, CPH</span>
                                            </div>
                                            <div class="arrival">
                                                <h4 class="time">04:25</h4>
                                                <h5 class="city">Dubai</h5>
                                            </div>
                                        </div>
                                    </div>

                                </div>

                                <div className="card__header return">
                                    <h5>End : Mon, 13 May</h5>
                                </div>
                                <div className="trip_details">
                                    <div className="grid">
                                        <p>Departure & Return</p>
                                        <span>Emirates airline / Iceland airline</span>
                                    </div>

                                    <div className="grid" style={{ width: "100%" }}>
                                        <p>Price per Person</p>
                                        <h1>1500 <span>USD</span></h1>
                                        <div className="select_flight">
                                            Select Flight
                                            <img src="/assets/img/icons/button-arrow.svg" alt="" />
                                        </div>
                                    </div>

                                </div>

                                {/* COST BREAK DOWN */}
                                <div className="tab__header">
                                    <h2>Cost Breakdown Section</h2>
                                </div>
                                <div className="invoice">
                                    <div className="tab__sub_header mt-0">
                                        <h2>Cost per Adult:</h2>
                                    </div>
                                    <div className="col-md-6">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th>Items</th>
                                                    <th>Cost (USD)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>Fixed Package Cost</td>
                                                    <th>$2000</th>
                                                </tr>
                                                <tr>
                                                    <td>Daily Vehicle Cost</td>
                                                    <th>$30</th>
                                                </tr>
                                                <tr>
                                                    <td>Daily Room Cost</td>
                                                    <th>$22</th>
                                                </tr>
                                                <tr>
                                                    <td>Flight Base Fare</td>
                                                    <th>$300</th>
                                                </tr>
                                                <tr>
                                                    <td>Flight Markup (2.2%)</td>
                                                    <th>$12</th>
                                                </tr>
                                                <tr>
                                                    <th>Total Cost per Adult</th>
                                                    <th>$2343</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                </div>


                                <div className="invoice pt-0">
                                    <div className="tab__sub_header mt-0">
                                        <h2>Total Cost</h2>
                                    </div>
                                    <div className="col-md-12">
                                        <table class="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>Category</th>
                                                    <th>Cost per Traveler (USD)</th>
                                                    <th>Number of Travelers</th>
                                                    <th>Total Cost (USD)</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td colSpan={3}>Adults</td>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <td colSpan={3}>Child (0-2)</td>
                                                    <th></th>
                                                </tr>
                                                <tr>
                                                    <th className='text-right' colSpan={3}>Final Total : </th>
                                                    <th>$234</th>
                                                </tr>
                                                <tr>
                                                    <th className='text-right' color='' colSpan={3}>Discount ( applied during booking) : </th>
                                                    <th>$234</th>
                                                </tr>
                                                <tr>
                                                    <th className='text-right' colSpan={3}>Token Payment : </th>
                                                    <th>$234</th>
                                                </tr>
                                                <tr>
                                                    <th className='text-right' colSpan={3}>Remaining Antiipcated Payment : </th>
                                                    <th>$234</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>


                                </div>
                            </div>
                        </div>

                    </div>
                }

                {
                    activeTab === 1 &&
                    <>
                        <div className="card">
                            <div className="card-body pt-0">
                                <div className='tab-content'>
                                    <div className="tab__header">
                                        <h2>Main Passenger</h2>
                                    </div>
                                    <div className="grid__items">
                                        <div className="item">
                                            <h4>First Name</h4>
                                            <input type="text" className='form-control' defaultValue={'John Doe'} />
                                        </div>
                                        <div className="item">
                                            <h4>Middle Name</h4>
                                            <input type="text" className='form-control' defaultValue={'Doe'} />
                                        </div>
                                        <div className="item">
                                            <h4>Last Name</h4>
                                            <input type="text" className='form-control' defaultValue={'Mankunnath'} />
                                        </div>
                                        <div className="item">
                                            <h4>Date of Birth</h4>
                                            <input type="date" className="form-control" defaultValue="1997-05-17" />
                                        </div>
                                        <div className="item">
                                            <h4>Nationality</h4>
                                            <input type="text" className='form-control' defaultValue={'Indian'} />
                                        </div>
                                        <div className="item">
                                            <h4>Email Address</h4>
                                            <input type="email" className='form-control' defaultValue={'mail@mail.com'} />
                                        </div>
                                        <div className="item">
                                            <h4>Phone Number</h4>
                                            <input type="text" className='form-control' defaultValue={'+917034327244'} />
                                        </div>
                                    </div>
                                    <div className="tab__sub_header">
                                        <h2>Passport Details</h2>
                                    </div>
                                    <div className="grid__items">
                                        <div className="item">
                                            <h4>Passport Number</h4>
                                            <input type="text" className='form-control' defaultValue={'John Doe'} />
                                        </div>
                                        <div className="item">
                                            <h4>Passport Expiry</h4>
                                            <input type="month" className="form-control" defaultValue="2024-07" />
                                        </div>
                                        <div className="item">
                                            <h4>Passport Copy</h4>
                                            <div className="files">
                                                <div className="file">
                                                    <img width={40} src="https://cdn.britannica.com/06/171306-050-C88DD752/Aurora-borealis-peninsula-Snaefellsnes-Iceland-March-2013.jpg" alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-12 mt-4">
                                    <button type="submit" className="btn btn-primary me-3">Submit</button>
                                    <button type="submit" className="btn btn-danger">Cancel</button>
                                </div>
                            </div>

                        </div>


                        <div className="card">
                            <div className="card-body pt-0">
                                <div className='tab-content'>
                                    <div className="tab__header">
                                        <h2>Adult 1</h2>
                                        <div className="toggle__btn mt-0">
                                            {/* <span>No</span> */}
                                            <Form.Check
                                                type="switch"
                                                id="custom-switch"
                                                checked
                                            />
                                            {/* <span>Yes</span> */}
                                        </div>
                                    </div>
                                    <div className="grid__items">
                                        <div className="item">
                                            <h4>First Name</h4>
                                            <input type="text" className='form-control' defaultValue={'John Doe'} />
                                        </div>
                                        <div className="item">
                                            <h4>Middle Name</h4>
                                            <input type="text" className='form-control' defaultValue={'Doe'} />
                                        </div>
                                        <div className="item">
                                            <h4>Last Name</h4>
                                            <input type="text" className='form-control' defaultValue={'Mankunnath'} />
                                        </div>
                                        <div className="item">
                                            <h4>Date of Birth</h4>
                                            <input type="date" className="form-control" defaultValue="1997-05-17" />
                                        </div>
                                        <div className="item">
                                            <h4>Nationality</h4>
                                            <input type="text" className='form-control' defaultValue={'Indian'} />
                                        </div>
                                        <div className="item">
                                            <h4>Email Address</h4>
                                            <input type="email" className='form-control' defaultValue={'mail@mail.com'} />
                                        </div>
                                        <div className="item">
                                            <h4>Phone Number</h4>
                                            <input type="text" className='form-control' defaultValue={'+917034327244'} />
                                        </div>
                                    </div>
                                    <div className="tab__sub_header">
                                        <h2>Passport Details</h2>
                                    </div>
                                    <div className="grid__items">
                                        <div className="item">
                                            <h4>Passport Number</h4>
                                            <input type="text" className='form-control' defaultValue={'John Doe'} />
                                        </div>
                                        <div className="item">
                                            <h4>Passport Expiry</h4>
                                            <input type="month" className="form-control" defaultValue="2024-07" />
                                        </div>
                                        <div className="item">
                                            <h4>Passport Copy</h4>
                                            <div className="files">
                                                <div className="file">
                                                    <img width={40} src="https://cdn.britannica.com/06/171306-050-C88DD752/Aurora-borealis-peninsula-Snaefellsnes-Iceland-March-2013.jpg" alt="" />
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                                <div className="col-12 mt-4">
                                    <button type="submit" className="btn btn-primary me-3">Submit</button>
                                    <button type="submit" className="btn btn-danger">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </>

                }
                {
                    activeTab === 2 &&
                    <>
                        <div className="card">
                            <div className="card-body pt-0">
                                <div className='tab-content'>
                                    <div className="tab__header">
                                        <h2>Main Passenger</h2>
                                    </div>
                                    <div className="grid__items" style={{ gridTemplateColumns: "repeat(1, 1fr)" }}>
                                        <div className="item">
                                            <p>Question 1</p>
                                            <h3 className='question'>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro et ullam beatae illo quisquam in ipsam accusamus eveniet dolor quasi vero iste culpa obcaecati, commodi saepe aut ratione, perspiciatis quam?</h3>
                                            <h4>Add Comment</h4>
                                            <textarea className='form-control' rows={4} placeholder='type here'></textarea>
                                            <div className="toggle__btn">
                                                <span>No</span>
                                                <Form.Check
                                                    type="switch"
                                                    id="custom-switch"
                                                    checked
                                                />
                                                <span>Yes</span>
                                            </div>

                                        </div>
                                    </div>
                                    <div className="col-12 mt-4">
                                        <button type="submit" className="btn btn-primary me-3">Submit</button>
                                        <button type="submit" className="btn btn-danger">Cancel</button>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </>

                }
                {
                    activeTab === 3 &&
                    <>

                        <div className="card">
                            <div className="card-body pt-0">
                                <div className='tab-content'>
                                    <div className="tab__header">
                                        <h2>Payments</h2>
                                    </div>
                                    <Accordion defaultActiveKey="0">
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Day One</Accordion.Header>
                                            <Accordion.Body>

                                                <div className="row mb-5">
                                                    <div className="col-12 ">
                                                        <div className="mb-3">
                                                            <label className="form-label">Day Title</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Type here"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Day Description</label>
                                                            <ReactQuill
                                                                theme="snow"
                                                                modules={{ toolbar: toolbarOptions }}
                                                                placeholder="Type here"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Activities</label>
                                                            <Select
                                                                isMulti
                                                                value={selectedOption}
                                                                onChange={handleChange}
                                                                options={options}
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select Inclusion"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mb-3">
                                                        {selectedOption.map((option) => (
                                                            <div key={option.value} className="d-inline-block mb-1">
                                                                <button
                                                                    className="btn btn-danger"
                                                                    type="button"
                                                                >
                                                                    {option.label}
                                                                    <span
                                                                        className="close_button"
                                                                        onClick={() => handleDelete(option)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        &times;
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Attractions</label>
                                                            <Select
                                                                isMulti
                                                                value={selectedOption}
                                                                onChange={handleChange}
                                                                options={options}
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select Inclusion"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mb-3">
                                                        {selectedOption.map((option) => (
                                                            <div key={option.value} className="d-inline-block mb-1">
                                                                <button
                                                                    className="btn btn-danger"
                                                                    type="button"
                                                                >
                                                                    {option.label}
                                                                    <span
                                                                        className="close_button"
                                                                        onClick={() => handleDelete(option)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        &times;
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Map Image</label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    placeholder="Type here"
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className="col-6">
                                                            <div className="mb-3">
                                                                <label className="form-label">Mobile App image</label>
                                                                <input
                                                                    type="file"
                                                                    className="form-control"
                                                                    placeholder="Type here"
                                                                />
                                                            </div>

                                                        </div>

                                                    </div>



                                                </div>
                                                <div class="tab__sub_header mt-4"><h2>Add Essential Documents</h2></div>
                                                <div className="grid__items col-3">
                                                    <div className="item">
                                                        <h4>Document type</h4>
                                                        <select class="form-select" aria-label="Default select example">
                                                            <option selected value="1">Ticket</option>
                                                            <option value="2">Hotel</option>
                                                            <option value="2">Other</option>
                                                        </select>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Title</h4>
                                                        <input type="text" placeholder='type here' className="form-control" />
                                                    </div>
                                                    <div className="item">
                                                        <h4>Upload Document</h4>
                                                        <input type="file" placeholder='type here' className="form-control" />
                                                    </div>
                                                    <div className="item grid-3">
                                                        <h4>Description</h4>
                                                        <textarea rows={3} placeholder='type description' className="form-control" />
                                                    </div>
                                                </div>
                                                <div className="col-12 mt-3">
                                                    <button type="submit" className="btn btn-primary me-3">Save</button>
                                                    <button type="submit" className="btn btn-danger ">Cancel</button>

                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>


                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Day Two</Accordion.Header>
                                            <Accordion.Body>

                                                <div className="row mb-5">

                                                    <div className="col-12 ">
                                                        <div className="mb-3">
                                                            <label className="form-label">Day Title</label>
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                placeholder="Type here"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="mb-3">
                                                            <label className="form-label">Description</label>
                                                            <ReactQuill
                                                                theme="snow"
                                                                modules={{ toolbar: toolbarOptions }}
                                                                placeholder="Type here"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 col-md-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Activities</label>
                                                            <Select
                                                                isMulti
                                                                value={selectedOption}
                                                                onChange={handleChange}
                                                                options={options}
                                                                closeMenuOnSelect={false}
                                                                placeholder="Select Inclusion"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div className="col-12 mb-3">
                                                        {selectedOption.map((option) => (
                                                            <div key={option.value} className="d-inline-block mb-1">
                                                                <button
                                                                    className="btn btn-danger"
                                                                    type="button"
                                                                >
                                                                    {option.label}
                                                                    <span
                                                                        className="close_button"
                                                                        onClick={() => handleDelete(option)}
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        &times;
                                                                    </span>
                                                                </button>
                                                            </div>
                                                        ))}
                                                    </div>

                                                    <div className="col-6">
                                                        <div className="mb-3">
                                                            <label className="form-label">Map Image</label>
                                                            <input
                                                                type="file"
                                                                className="form-control"
                                                                placeholder="Type here"
                                                            />
                                                        </div>
                                                    </div>

                                                    <div class="tab__sub_header mt-4"><h2>Add Essential Documents</h2></div>
                                                    <div className="grid__items col-3">
                                                        <div className="item">
                                                            <h4>Document type</h4>
                                                            <select class="form-select" aria-label="Default select example">
                                                                <option selected value="1">Ticket</option>
                                                                <option value="2">Hotel</option>
                                                                <option value="2">Other</option>
                                                            </select>
                                                        </div>
                                                        <div className="item">
                                                            <h4>Title</h4>
                                                            <input type="text" placeholder='type here' className="form-control" />
                                                        </div>
                                                        <div className="item">
                                                            <h4>Upload Document</h4>
                                                            <input type="file" placeholder='type here' className="form-control" />
                                                        </div>
                                                        <div className="item grid-3">
                                                            <h4>Description</h4>
                                                            <textarea rows={3} placeholder='type description' className="form-control" />
                                                        </div>
                                                    </div>
                                                    <div className="col-12 mt-3">
                                                        <button type="submit" className="btn btn-primary me-3">Save</button>
                                                        <button type="submit" className="btn btn-danger ">Cancel</button>

                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </div>
                            </div>

                        </div>
                    </>
                }
                {
                    activeTab === 4 &&
                    <>

                        <div className="card">
                            <div className="card-body pt-0">
                                <div className='tab-content'>
                                    <div className="tab__header">
                                        <h2>Add Updates</h2>
                                    </div>
                                    <div className="quotation">
                                        <table class="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">Final Quote</th>
                                                    <th scope="col">Unit Cost ( USD )</th>
                                                    <th scope="col">Units</th>
                                                    <th scope="col">Total ( USD )</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th colSpan={4}>Package Cost</th>
                                                </tr>
                                                <tr>
                                                    <td>Adult</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <td>Child (12+), 100%</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <td>Child (05-12), 80%</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <td>Child (03-05), 50%</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <td>Infant (0-12)</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <th colSpan={4}>Airfare Cost</th>
                                                </tr>
                                                <tr>
                                                    <td>Adult</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <td>Child (12+), 100%</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <td>Child (05-12), 80%</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <td>Child (03-05), 50%</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <td>Infant (0-12)</td>
                                                    <td><input type="text" className='form-control' placeholder='Unit Cost' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Units' /></td>
                                                    <td><input type="text" className='form-control' placeholder='Total' /></td>
                                                </tr>
                                                <tr>
                                                    <th colSpan={3}>Gross Total</th>
                                                    <th>35,200</th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={3}>Payment Completed</th>
                                                    <th>2,300</th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={3}>Discounts</th>
                                                    <th>300</th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={3}>Deductions</th>
                                                    <th>1000</th>
                                                </tr>
                                                <tr>
                                                    <th colSpan={3}>Total To Be Paid</th>
                                                    <th>31,600</th>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    <div className="col-md-12 mt-4">
                                        <button type="submit" className="btn btn-primary me-3">Submit</button>
                                        <button type="submit" className="btn btn-danger">Cancel</button>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </>
                }

                {
                    activeTab === 5 &&
                    <>

                        <div className="card">
                            <div className="card-body pt-0">
                                <div className='tab-content'>
                                    <div className="row">
                                        <Tabs.Root className="TabsRoot" defaultValue="tab1">
                                            <Tabs.List className="TabsList" aria-label="Manage your account">
                                                <Tabs.Trigger className="TabsTrigger" value="tab1">
                                                    Token Payment Details
                                                </Tabs.Trigger>
                                                <Tabs.Trigger className="TabsTrigger" value="tab2">
                                                    Payment Details
                                                </Tabs.Trigger>
                                                {/* <Tabs.Trigger className="TabsTrigger" value="tab3">
                                                    Milestone 2
                                                </Tabs.Trigger> */}
                                            </Tabs.List>
                                            <Tabs.Content className="TabsContent" value="tab1">
                                                <div className="tab__header mt-0">
                                                    <h2>Payments</h2>
                                                </div>
                                                <div className="grid__items">
                                                    <div className="item">
                                                        <h4>Transaction ID</h4>
                                                        <p>SKY0012</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Amount</h4>
                                                        <p>900</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Currency</h4>
                                                        <p>lorem ipsum</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Payment Status</h4>
                                                        <p className='badge bg-success'>Recieved</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Payment Method</h4>
                                                        <p>Card</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Customer Stripe id</h4>
                                                        <p>SKYWARD001221</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Customer id</h4>
                                                        <p>SKY1322</p>
                                                    </div>

                                                </div>
                                                <div class="tab__sub_header mt-4"><h2>Billing Details</h2></div>
                                                <div className="grid__items">
                                                    <div className="item">
                                                        <h4>Name</h4>
                                                        <p>John Doe</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Email</h4>
                                                        <p>email@mail.com</p>
                                                    </div>
                                                    <div className="item grid-2">
                                                        <h4>Address</h4>
                                                        <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ex aliquid nobis.</p>
                                                    </div>
                                                </div>
                                                <div class="tab__sub_header mt-4"><h2>Milestone Details</h2></div>
                                                <span className='badge bg-success mb-3'>First Milestone</span>
                                                <div className="grid__items">
                                                    <div className="item">
                                                        <h4>Milestone type</h4>
                                                        <p>One time</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Transaction ID</h4>
                                                        <p>SKY0012</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Amount</h4>
                                                        <p>900</p>
                                                    </div>

                                                    <div className="item">
                                                        <h4>Payment Status</h4>
                                                        <span className='badge bg-success'>Recieved</span>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Currency</h4>
                                                        <p>lorem ipsum</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Payment Method</h4>
                                                        <p>Card</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Customer Stripe id</h4>
                                                        <p>SKYWARD001221</p>
                                                    </div>

                                                    <div className="item">
                                                        <h4>Milestone Name</h4>
                                                        <p>First Payment</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Due Date</h4>
                                                        <p>20 may 2024</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Percentage</h4>
                                                        <p>20%</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>value</h4>
                                                        <p>400</p>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Uploaded File</h4>
                                                        <div className="files">
                                                            <img src="/assets/img/pdf.jpg" alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </Tabs.Content>
                                            <Tabs.Content className="TabsContent" value="tab2">
                                                <div className="grid__items mb-4 pad-0">
                                                    <div className="item">
                                                        <h4>Select Payment type</h4>
                                                        <select class="form-select" aria-label="Default select example" onChange={milestoneSelect}>
                                                            <option selected value="1">One Time</option>
                                                            <option value="2">Milestone</option>
                                                        </select>
                                                    </div>
                                                    <div className="item">
                                                        <h4>Payment Method</h4>
                                                        <select class="form-select" aria-label="Default select example">
                                                            <option selected value="1">Online Payment</option>
                                                            <option value="2">Direct Bank</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="grid__items pad-0">

                                                    {
                                                        milestone == 2 &&

                                                        <>
                                                            <div className="item">
                                                                <h4>Milestone Name</h4>
                                                                <input type="text" className='form-control' placeholder='Type here' />
                                                            </div>
                                                            <div className="item">
                                                                <h4>Percentage</h4>
                                                                <input type="text" className='form-control' placeholder='%' />
                                                            </div>
                                                            <div className="item">
                                                                <h4>Value</h4>
                                                                <input type="text" className='form-control' placeholder='Value' />
                                                            </div>
                                                            <div className="item">
                                                                <h4>Date</h4>
                                                                <input type="date" className="form-control" defaultValue="1997-05-17" />
                                                            </div>
                                                            <div className="item">
                                                                <h4>Payment Status</h4>
                                                                <select class="form-select">
                                                                    <option selected value="1">Payment Initiated</option>
                                                                    <option value="2">Payment Pending</option>
                                                                    <option value="3">Payment Captured</option>
                                                                    <option value="4">Payment Failed</option>
                                                                </select>
                                                            </div>
                                                            <div className="item mb-1" style={{ display: 'flex', alignItems: 'end' }}>
                                                                <button type="submit" className="btn btn-primary me-3">Add</button>
                                                            </div>

                                                        </>
                                                    }

                                                    {
                                                        milestone == 1 &&

                                                        <>
                                                            <div className="item">
                                                                <h4>Milestone Name</h4>
                                                                <input type="text" className='form-control' placeholder='Type here' />
                                                            </div>
                                                            <div className="item">
                                                                <h4>Upload Receipt</h4>
                                                                <input type="file" className='form-control' placeholder='%' />
                                                            </div>
                                                            <div className="item">
                                                                <h4>Date</h4>
                                                                <input type="date" className="form-control" defaultValue="1997-05-17" />
                                                            </div>
                                                        </>
                                                    }
                                                    {
                                                        milestone == 2 && <div className="remaining__amount"><p>Remaining Amount : $300</p></div>
                                                    }

                                                </div>
                                                <div className="col-12 mt-4">
                                                    <button type="submit" className="btn btn-primary me-3">Submit</button>
                                                    <button type="submit" className="btn btn-danger ">Cancel</button>
                                                </div>
                                            </Tabs.Content>

                                            {/* <Tabs.Content className="TabsContent" value="tab3">
                                                <h1>Milestone 2</h1>
                                            </Tabs.Content> */}

                                        </Tabs.Root>


                                    </div>

                                </div>
                            </div>

                        </div>
                    </>
                }
                {
                    activeTab === 6 &&
                    <div className="card">
                        <div className="card-body pt-0">
                            <div className="row">
                                <Tabs.Root className="TabsRoot" defaultValue="tab1">
                                    <Tabs.List className="TabsList" aria-label="Manage your account">
                                        <Tabs.Trigger className="TabsTrigger" value="tab1">
                                            Passenger Information
                                        </Tabs.Trigger>
                                        <Tabs.Trigger className="TabsTrigger" value="tab2">
                                            Customer Engagement
                                        </Tabs.Trigger>
                                        <Tabs.Trigger className="TabsTrigger" value="tab3">
                                            Travel Itinerary
                                        </Tabs.Trigger>
                                        <Tabs.Trigger className="TabsTrigger" value="tab4">
                                            Quotation
                                        </Tabs.Trigger>
                                        {/* <Tabs.Trigger className="TabsTrigger" value="tab5">
                                            Payments
                                        </Tabs.Trigger> */}
                                    </Tabs.List>
                                    <Tabs.Content className="TabsContent" value="tab1">
                                        <div className="tab-content">
                                            <div className="tab__sub_header">
                                                <h2>Passenger Info</h2>
                                            </div>
                                            <div className="grid__items">
                                                <div className="item">
                                                    <h4>First Name</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Middle Name</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Last Name</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Date of Birth</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Nationality</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Email Address</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Phone Number</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                            </div>
                                            <div className="tab__sub_header">
                                                <h2>Travel Details</h2>
                                            </div>
                                            <div className="grid__items">
                                                <div className="item">
                                                    <h4>Passport Number</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Passport Expiry</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Passport Copy</h4>
                                                    <div className="files">
                                                        <img src="/assets/img/pdf.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab__sub_header child">
                                                <h2>Child 1</h2>
                                            </div>
                                            <div className="grid__items">
                                                <div className="item">
                                                    <h4>First Name</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Middle Name</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Last Name</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Date of Birth</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Nationality</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Email Address</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Phone Number</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Passport Number</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Passport Expiry</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Passport Copy</h4>
                                                    <div className="files">
                                                        <img src="/assets/img/pdf.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="tab__sub_header child">
                                                <h2>Child 2</h2>
                                            </div>
                                            <div className="grid__items">
                                                <div className="item">
                                                    <h4>First Name</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Middle Name</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Last Name</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Date of Birth</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Nationality</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Email Address</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Phone Number</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Passport Number</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Passport Expiry</h4>
                                                    <p>lorem ipsum</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Passport Copy</h4>
                                                    <div className="files">
                                                        <img src="/assets/img/pdf.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Tabs.Content>
                                    <Tabs.Content className="TabsContent" value="tab2">
                                        <div className="tab-content">
                                            <div className="tab__sub_header">
                                                <h2>Question 1</h2>
                                            </div>
                                            <div className="grid__items">
                                                <div className="item grid-4">
                                                    <h4>Question</h4>
                                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro et ullam beatae illo quisquam in ipsam accusamus eveniet dolor quasi vero iste culpa obcaecati, commodi saepe aut ratione, perspiciatis quam?</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Comment</h4>
                                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro et ullam beatae illo quisquam in ipsam accusamus eveniet dolor quasi vero iste culpa obcaecati, commodi saepe aut ratione, perspiciatis quam?</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Status</h4>
                                                    <span className='toggle_status'>Yes</span>
                                                </div>
                                            </div>
                                            <div className="tab__sub_header">
                                                <h2>Question 2</h2>
                                            </div>
                                            <div className="grid__items">
                                                <div className="item grid-4">
                                                    <h4>Question</h4>
                                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro et ullam beatae illo quisquam in ipsam accusamus eveniet dolor quasi vero iste culpa obcaecati, commodi saepe aut ratione, perspiciatis quam?</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Comment</h4>
                                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro et ullam beatae illo quisquam in ipsam accusamus eveniet dolor quasi vero iste culpa obcaecati, commodi saepe aut ratione, perspiciatis quam?</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Status</h4>
                                                    <span className='toggle_status no'>No</span>
                                                </div>
                                            </div>

                                            <div className="tab__sub_header">
                                                <h2>Question 3</h2>
                                            </div>
                                            <div className="grid__items">
                                                <div className="item grid-4">
                                                    <h4>Question</h4>
                                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro et ullam beatae illo quisquam in ipsam accusamus eveniet dolor quasi vero iste culpa obcaecati, commodi saepe aut ratione, perspiciatis quam?</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Comment</h4>
                                                    <p>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Porro et ullam beatae illo quisquam in ipsam accusamus eveniet dolor quasi vero iste culpa obcaecati, commodi saepe aut ratione, perspiciatis quam?</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Status</h4>
                                                    <span className='toggle_status'>Yes</span>
                                                </div>
                                            </div>

                                        </div>
                                    </Tabs.Content>

                                    <Tabs.Content className="TabsContent" value="tab3">
                                    <Accordion>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>Day One</Accordion.Header>
                                            <Accordion.Body>
                                            <div className="tab-content">
                                            <div className="grid__items">
                                                <div className="item grid-4">
                                                    <h4>Day Title</h4>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi veritatis, atque quis ab adipisci voluptatum.</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Day Description</h4>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi veritatis, atque quis ab adipisci voluptatum.</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Activities</h4>
                                                    <ul className='activities'>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                    </ul>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Attractions</h4>
                                                    <ul className='activities'>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                    </ul>
                                                </div>
                                                <div className="item">
                                                    <h4>Map Image</h4>
                                                    <img className='map-image w-100' src="https://cdn2.f-cdn.com/contestentries/2348139/49010916/657c9315b1366_thumb900.jpg" alt="" />
                                                </div>
                                                <div className="item">
                                                    <h4>Mobile App image</h4>
                                                    <img className='map-image w-100' src="https://cdn2.f-cdn.com/contestentries/2348139/49010916/657c9315b1366_thumb900.jpg" alt="" />
                                                </div>
                                                <div className="item grid-2">
                                                    <h4>Document type</h4>
                                                    <p>Ticket</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>title</h4>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Description</h4>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores autem dolorum ipsum placeat ea sapiente expedita pariatur fuga non saepe culpa.</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Uploaded Document</h4>
                                                    <div className="files">
                                                        <img src="https://cdn.britannica.com/06/171306-050-C88DD752/Aurora-borealis-peninsula-Snaefellsnes-Iceland-March-2013.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            </Accordion.Body>
                                        </Accordion.Item>


                                        <Accordion.Item eventKey="1">
                                            <Accordion.Header>Day Two</Accordion.Header>
                                            <Accordion.Body>
                                            <div className="tab-content">
                                            <div className="grid__items">
                                                <div className="item grid-4">
                                                    <h4>Day Title</h4>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi veritatis, atque quis ab adipisci voluptatum.</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Day Description</h4>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Modi veritatis, atque quis ab adipisci voluptatum.</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Activities</h4>
                                                    <ul className='activities'>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                    </ul>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Attractions</h4>
                                                    <ul className='activities'>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                        <li>lorem ipsum</li>
                                                    </ul>
                                                </div>
                                                <div className="item">
                                                    <h4>Map Image</h4>
                                                    <img className='map-image w-100' src="https://cdn2.f-cdn.com/contestentries/2348139/49010916/657c9315b1366_thumb900.jpg" alt="" />
                                                </div>
                                                <div className="item">
                                                    <h4>Mobile App image</h4>
                                                    <img className='map-image w-100' src="https://cdn2.f-cdn.com/contestentries/2348139/49010916/657c9315b1366_thumb900.jpg" alt="" />
                                                </div>
                                                <div className="item grid-2">
                                                    <h4>Document type</h4>
                                                    <p>Ticket</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>title</h4>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit.</p>
                                                </div>
                                                <div className="item grid-4">
                                                    <h4>Description</h4>
                                                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores autem dolorum ipsum placeat ea sapiente expedita pariatur fuga non saepe culpa.</p>
                                                </div>
                                                <div className="item">
                                                    <h4>Uploaded Document</h4>
                                                    <div className="files">
                                                        <img src="https://cdn.britannica.com/06/171306-050-C88DD752/Aurora-borealis-peninsula-Snaefellsnes-Iceland-March-2013.jpg" alt="" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                        
                                    </Tabs.Content>

                                </Tabs.Root>


                            </div>

                        </div>

                    </div>
                }




                <div className="history__tabs">
                    <ul>
                        {historyTabs.map((tab, index) => (
                            <li
                                key={index}
                                className={activeHistoryTab === index ? 'active' : ''}
                                onClick={() => setActiveHistoryTab(index)}
                            >
                                {tab}
                            </li>
                        ))}
                    </ul>
                    {
                        activeHistoryTab === 0 && <CommentUpdates />
                    }
                    {
                        activeHistoryTab === 1 && <History />
                    }
                </div>



            </div>
        </div>
    )
}

export default ViewBooking