import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  getFlightCostDetail,
  insertFlightCost,
} from "../../redux/actionCreator";
import {
  detail_flight_cost_loading,
  flight_cost_details,
  update_flight_cost_loading,
} from "../../redux/commonReducer";

export default function FlightMarkup() {
  const dispatch = useDispatch();

  const submitLoading = useSelector(update_flight_cost_loading);
  const detailLoading = useSelector(detail_flight_cost_loading);
  const details = useSelector(flight_cost_details);

  const [data, setData] = useState({
    flightmarkup_cost: "",
    booking_cost: "",
  });
  const [err, setErr] = useState({});

  useEffect(() => {
    dispatch(getFlightCostDetail());
  }, []);

  useEffect(() => {
    if (details) {
      setData({
        flightmarkup_cost: details?.flightmarkup_cost,
        booking_cost: details?.booking_cost,
      });
    }
  }, [details]);

  const onChangeHandler = (key, value) => {
    console.log(key, value);
    setData({
      ...data,
      [key]: value,
    });
    setErr({
      ...err,
      [key]: "",
    });
  };

  const validate = () => {
    let temp = {};
    if (!data?.flightmarkup_cost?.trim()?.length) {
      temp = {
        ...temp,
        flightmarkup_cost: "Please enter value",
      };
    }
    if (!data?.booking_cost?.trim()?.length) {
      temp = {
        ...temp,
        booking_cost: "Please enter value",
      };
    }

    if (Object.values(temp).length != 0) {
      setErr(temp);
      return false;
    } else {
      setErr({});
      return true;
    }
  };
  const onSubmitHandler = () => {
    if (validate()) {
      let raw = {
        flightmarkup_cost: data?.flightmarkup_cost?.trim(),
        booking_cost: data?.booking_cost?.trim(),
      };
      console.log(raw);
      dispatch(
        insertFlightCost(raw, (res) => {
          if (res) {
            onCancelHandler();
          }
        })
      );
    }
  };

  const onCancelHandler = () => {
    setData({
      flightmarkup_cost: "",
      booking_cost: "",
    });
    dispatch(getFlightCostDetail());
  };

  return (
    <div className="row">
      <div className="col">
        <div className="card">
          <div className="card-body">
            <h5 className="card-title border-style mb-3">Flight Markup Cost</h5>
            <div className="row">
              <div className="col-12 col-md-7">
                <div className="mb-3">
                  <label for="formGroupExampleInput" className="form-label">
                    Flight Markup Cost (%)
                  </label>
                  <input
                    type="number"
                    className="form-control typeNumber"
                    id={`ageGroupInput1`}
                    placeholder="Type here"
                    value={data?.flightmarkup_cost}
                    onChange={(e) =>
                      onChangeHandler("flightmarkup_cost", e?.target?.value)
                    }
                  />
                  <small id="error" className="error">
                    {err?.flightmarkup_cost}
                  </small>
                </div>
              </div>
              <div className="col-12 col-md-7">
                <div className="mb-3">
                  <label for="formGroupExampleInput" className="form-label">
                    Booking Cost
                  </label>
                  <input
                    type="number"
                    className="form-control typeNumber"
                    id={`ageGroupInput1`}
                    placeholder="Type here"
                    value={data?.booking_cost}
                    onChange={(e) =>
                      onChangeHandler("booking_cost", e?.target?.value)
                    }
                  />
                  <small id="error" className="error">
                    {err?.booking_cost}
                  </small>
                </div>
              </div>
              <div className="col-12">
                <button
                  type="submit"
                  className="btn btn-primary mt-2 me-2 "
                  onClick={() => onSubmitHandler()}
                >
                  Submit
                </button>
                <button
                  type="submit"
                  className="btn btn-danger"
                  onClick={() => onCancelHandler()}
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
