import React from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'

export default function ManagePackages() {
  return (
    <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <div className="table__add border-style">
                            <h5 className="card-title">Manage Packages</h5>
                     
                         <Link to='/add-package'><button className='btn btn-primary'>Add Package</button></Link>
                      
                        </div>
                        <div className="search__head">
                            <div className="count">
                                <p>Show</p>
                                <input type="number" className='form-control' value={10} />
                                <p>entries</p>
                            </div>
                            <div className="search">
                                <input type="text" className='form-control' placeholder='Search here' />
                                <i data-feather="search"></i>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                                <tr>
                                    <th scope="col">SL.No</th>
                                    <th>ID</th>
                                    <th className='long_text'>Package Name</th>
                                    <th>Duration</th>
                                    <th className='long_text'>Last Updated</th>
                                    <th>Rank</th>
                                    <th>Status</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>SKIL01</td>
                                    <td>Ring Road Classic – Fast Paced In 14 Days</td>
                                    <td>14</td>
                                    <td>28 Oct 2024</td>
                                    <td><input type="text" className='form-control' defaultValue={5} /></td>
                                    <td><span className='badge bg-success'>Active</span></td>
                                    <td className='actions'>
                                        <button className="btn-sm btn-primary"><i data-feather="edit"></i></button>
                                        <button className="btn-sm btn-primary"><i data-feather="trash-2"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className="default__pagination">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel={<i data-feather="chevron-right"></i>}
                                pageRangeDisplayed={3}
                                marginPagesDisplayed={2}
                                pageCount={2}
                                previousLabel={<i data-feather="chevron-left"></i>}
                                renderOnZeroPageCount={null}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}
