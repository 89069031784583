import React from 'react'

export default function CommentUpdates() {
    return (
        <div className="card">
            <div className="card-body">
            <h4 className="card-title border-style mb-3">Add comments and attachments</h4>
                <div className="grid__items" style={{ gridTemplateColumns: "repeat(1, 1fr)" }}>
                    <div className="item">
                        <textarea className='form-control' rows={4} placeholder='type comments here'></textarea>
                    </div>
                    <div className="item mt-4">
                        <input type="file" className='form-control' />
                    </div>
                </div>
                <div className="col-md-12 mt-4">
                    <button type="submit" className="btn btn-primary me-3">Submit</button>
                    <button type="submit" className="btn btn-danger">Cancel</button>
                </div>
                <div className="row mt-4">

                    <h4 className="card-title border-style mb-3">Comment Updates</h4>


                    <div className="card history__card">
                        <div className="history-card">
                            <div className="icon">
                                <i className="far fa-calendar-check"></i>
                            </div>
                            <div className="card-element">
                                <div className="history-header ps-4">
                                    <h6 style={{ color: '#0279b0' }}>08 Aug 2023</h6>
                                    Lorem ipsum dolor, sit amet consectetur adipisicing elit. Magni eum sequi repellendus, eos voluptas architecto consequuntur vel repudiandae facilis eveniet reiciendis quisquam
                                </div>
                                <div className="card-body ps-4">
                                    <div className="updates__images">
                                        <img src="https://pictures.altai-travel.com/1920x0/the-amazing-skogafoss-falls-in-iceland-adobe-stock-2847.jpg" alt="" />
                                        <img src="https://pictures.altai-travel.com/1920x0/the-amazing-skogafoss-falls-in-iceland-adobe-stock-2847.jpg" alt="" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
    )
}
