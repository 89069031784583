import React, { useState } from 'react'
import MediaUploader from '../components/media-uploader/MediaUploader'
import IconUploader from '../components/media-uploader/IconUploader';


const GeneralPage = () => {
    const [fields, setFields] = useState([{ icon: null, link: '' }]);
    const handleDescriptionChange = (index, link) => {
        const updatedFields = [...fields];
        updatedFields[index].link = link;
        setFields(updatedFields);
    };

    const handleRemoveField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };


    const handleAddField = () => {
        setFields([...fields, { icon: null, link: '' }]);
    };

    return (
        <div className="row">
            <div className="col">


                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3">Blog Page </h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12  mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Short Description</label>
                                    <textarea className='form-control ' rows='2' placeholder='Type here'></textarea>

                                </div>
                            </div>

                            <div className="col-12 col-md-4 mb-3">
                                <label className="form-label">Banner Image</label>
                                <MediaUploader />
                            </div>
                        </div>

                    </div>
                </div>


                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3">Token Payment</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Reserve Payment</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3">Social Media</h5>

                        <div>
                            <div className="row">
                                {fields.map((field, index) => (
                                    <div key={index} className="row mb-2  align-items-center">
                                        <div className="col-12 col-md-2">
                                            <IconUploader
                                                index={index}
                                            />
                                        </div>
                                        <div className="col-12 col-md-5">
                                            <label className="form-label">{index + 1}.Link</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Type here"
                                                value={field.title}
                                                onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12 col-md-2 preview-imagemargin">
                                            {index === fields.length - 1 ? (
                                                <button className="btn btn-primary  " onClick={handleAddField}>
                                                    Add
                                                </button>
                                            ) : (
                                                <button className="btn btn-danger " onClick={() => handleRemoveField(index)}>
                                                    Delete
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3">Common Footer</h5>
                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Logo</label>
                                    <MediaUploader/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary m-2">Submit</button>
                            <button type="submit" className="btn btn-danger">Cancel</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>



    )
}

export default GeneralPage