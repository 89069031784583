import React, { useState } from 'react'
import MediaUploader from '../components/media-uploader/MediaUploader'
import IconUploader from '../components/media-uploader/IconUploader'
import PackageFooter from '../components/PackageFooter';


const PackagePage = () => {


    const [fields, setFields] = useState([{ icon: null, description: '' }]);
    const handleDescriptionChange = (index, description) => {
        const updatedFields = [...fields];
        updatedFields[index].description = description;
        setFields(updatedFields);
    };

    const handleRemoveField = (index) => {
        const updatedFields = fields.filter((_, i) => i !== index);
        setFields(updatedFields);
    };


    const handleAddField = () => {
        setFields([...fields, { icon: null, description: '' }]);
    };
    return (

        <div className="row">
            <div className="col">
                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3">Banner Section</h5>

                        <div className="row">
                            <div className="col-12">
                                <div className="mb-3">
                                    <label className="form-label">Title</label>
                                    <input type="text" className="form-control" placeholder="Type here" />
                                </div>
                            </div>
                            <div className="col-12  mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Short Description</label>
                                    <textarea className='form-control ' rows='2' placeholder='Type here'></textarea>

                                </div>
                            </div>

                            <div className="col-12 col-md-4 mb-3">
                                <label className="form-label">Banner Image</label>
                                <MediaUploader />
                            </div>
                            <div className="col-12 col-md-8 mb-3">
                                <label className="form-label">Image Alt</label>
                                <input type="text" className="form-control" placeholder="Type here" />
                            </div>

                            <div className="col-12  mb-3">
                                <div className="mb-3">
                                    <label className="form-label">Search Content</label>
                                    <textarea className='form-control ' rows='2' placeholder='Type here'></textarea>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="card">
                    <div className="card-body">
                        <h5 className="card-title border-style mb-3">Package Included </h5>
                        <div>
                            <div className="row">
                                {fields.map((field, index) => (
                                    <div key={index} className="row mb-3 align-items-center">
                                        <div className="col-12 col-md-2">
                                            <IconUploader
                                                index={index}
                                            />
                                        </div>
                                        <div className="col-12 col-md-5">
                                            <label className="form-label">{index + 1}.Description</label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder="Type here"
                                                value={field.title}
                                                onChange={(e) => handleDescriptionChange(index, e.target.value)}
                                            />
                                        </div>
                                        <div className="col-12 col-md-2 preview-imagemargin">
                                            {index === fields.length - 1 ? (
                                                <button className="btn btn-primary  " onClick={handleAddField}>
                                                    Add
                                                </button>
                                            ) : (
                                                <button className="btn btn-danger " onClick={() => handleRemoveField(index)}>
                                                    Delete
                                                </button>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                       <PackageFooter title={"Package Page Footer"}/>
                    </div>
                </div>

                <div className="card">
                    <div className="card-body">
                    <PackageFooter title={"Flight Page Footer"}/>
                    </div>
                </div>

               


                <div className="card">
                    <div className="card-body">
                        <div className="col-12">
                            <button type="submit" className="btn btn-primary m-2">Submit</button>
                            <button type="submit" className="btn btn-danger">Cancel</button>
                        </div>

                    </div>
                </div>

            </div>
        </div>
    )
}

export default PackagePage